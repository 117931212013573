import React,{useEffect,useState} from 'react'
import '../career/career.css'
import DahooksBanner from '../../assets/tac-banner.jpg'
import { useLocation } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";

const Career = (props) => {
    const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  
  const onOpenModal = () => {
    setOpen(true)
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit=()=>{
    if(name=='' && email=="" && message==""){
      setErrr("All field are required!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    const emailReg=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!email.match(emailReg)){
          setErrr("Please enter valid email!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
        return false
        }

        if(message?.length>500){
          setErrr("Messahe should be contain 500 charectors !!");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return false
        }
     
        axios.post("https://smtp.taction.in/api/api/v2/send-mail",{
          name:name,
          email:email,
          message:message
        }).then(res=>{
          if(res && res?.status==200){
            toast.success('Your query is Submitted ', {
              autoClose: 2000,
            });
            setTimeout(() => {
              setOpen(false);
              setEmail("");
              setName("");
              setMessage("")
            }, 2500);
          }
         
        }).catch((err)=>{
          console.log(err)
        })
        console.log("Contact")

    
    
  }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
  return (
    <>
        <Helmet>
          <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Careers at Dahooks | Apply for a Job Today!" /><meta property="og:description" content="Dahooks provides excellent career opportunity Our team is always looking for individuals who are results-driven and creative thinkers, and we prioritize ethical behavior. Join us and develop your skills." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Careers at Dahooks | Apply for a Job Today!" /><meta name="twitter:description" content="Dahooks provides excellent career opportunity Our team is always looking for individuals who are results-driven and creative thinkers, and we prioritize ethical behavior. Join us and develop your skills." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
          <title>Careers at Dahooks | Apply for a Job Today!</title>
          <link rel="canonical" href="https://dahooks.com/career" />
        </Helmet>
        <div className='container pt-5'>
            <div className='row pt-5'>
                <div className='col-12 text-center mt-5'>
                    <div className='career-heading pt-5 pb-4'>We offer more than a job</div>
                    <div className='career-text'>
                      If you're an expert in your field with a burning desire to learn, develop, and have an impact, then you've already made progress. We offer more than just a job - this is a place where you can create and provide something valuable while receiving much more in return. Our team is always looking for individuals who are results-driven and creative thinkers, and we prioritize ethical behavior. Join us and develop your skills.
                    </div>
                    {/* <div className='career-text pt-4 pb-2'>We hire character. Train skill.</div> */}
                    <div className='career-heading pt-5 pb-4'>Let's get motivated and complete the task together.</div>
                    <div className='career-text pb-2'>
                      Our ability to adapt, embrace advancements, and utilize technology and strategic planning are the key factors that make our collaboration so effortless. Irrespective of their demographic, anyone can work together on multiple tasks. Our goal is to always work in an environment that is inclusive, autonomous, and accountable.
                    </div>
                </div>
            </div>
            <div className='row pb-3'>
                <div className='text-center career-heading pt-5 pb-4'>We're Looking For</div>
                <div className='col-lg-6 col-12' onClick={onOpenModal}>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>Sr. Java Developer</div>
                        <div className='possition'>2 Positions</div>
                    </div>
                </div>
                <div className='col-lg-6 col-12' onClick={onOpenModal}>
                    <div className='d-flex justify-content-between mt-3 mt-md-0 p-md-4 p-2 business-sec'>
                        <div>Java Developer</div>
                        <div className='possition'>4 Positions</div>
                    </div>
                </div>
            </div>
            <div className='row pb-3'>
                <div className='col-lg-6 col-12' onClick={onOpenModal}>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>QA</div>
                        <div className='possition'>2 Positions</div>
                    </div>
                </div>
                <div className='col-lg-6 col-12' onClick={onOpenModal}>
                    <div className='d-flex justify-content-between mt-3 mt-md-0 p-md-4 p-2 business-sec'>
                        <div>React Native</div>
                        <div className='possition'>2 Positions</div>
                    </div>
                </div>
            </div>
            <div className='row pb-3'>
                <div className='col-lg-6 col-12' onClick={onOpenModal}>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>DevOps / Network Engineer</div>
                        <div className='possition my-auto'>2 Positions</div>
                    </div>
                </div>
                <div className='col-lg-6 col-12' onClick={onOpenModal}>
                    <div className='d-flex justify-content-between mt-3 mt-md-0 p-md-4 p-2 business-sec'>
                        <div>Magento Developer</div>
                        <div className='possition my-auto'>2 Positions</div>
                    </div>
                </div>
            </div>
            {/* <div className='row pb-3'>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>Marketing Executive - Dubai</div>
                        <div className='possition'>1 Positions</div>
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between mt-3 mt-md-0 p-md-4 p-2 business-sec'>
                        <div>Wordpress Developer</div>
                        <div className='possition'>2 Positions</div>
                    </div>
                </div>
            </div>
            <div className='row pb-3'>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>Drupal Developers</div>
                        <div className='possition'>2 Positions</div>
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between mt-3 mt-md-0 p-md-4 p-2 business-sec'>
                        <div>Manager - Business Development</div>
                        <div className='possition'>2 Positions</div>
                    </div>
                </div>
            </div>
            <div className='row pb-3'>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>Social Media Marketing<br className='d-md-none'/> & Creative<br className='d-none d-md-block'/>Executive</div>
                        <div className='possition my-auto'>1 Positions</div>
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between mt-3 mt-md-0 p-md-4 p-2 business-sec'>
                        <div>Lead Generation<br/>Specialist</div>
                        <div className='possition my-auto'>1 Positions</div>
                    </div>
                </div>
            </div>
            <div className='row pb-3 justify-content-center'>
                <div className='career-heading text-center pt-5 pb-4'>Ways we can work together</div>
                <div className='col-lg-6 col-12'>
                    <div className='d-flex justify-content-between p-md-4 p-2 business-sec'>
                        <div>Fullstack Dot Net Developers <br/>( DotNet & Angular/ReactJS)</div>
                        <div className='possition my-auto'>4 Positions</div>
                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='career-heading pt-5 pb-4'>The criteria required to become a member of the group</div>
                <div className='col-lg-6 col-12'>
                    <div className='career-text'>
                      People are the centre of all we do, so please join us. With the help of all the contemporary equipment and amenities, our workplace guarantees that energy and productivity never fade. Everyone has realized that life is more than just a job. We acknowledge this and provide all the necessary processes and accommodations to ensure you meet your personal and family obligations.
                    </div>
                </div>
                <div className='col-lg-6 col-12'>
                    <div className='career-text'>
                      Make yourself heard if your excitement, passion, and originality offer value. When you need them, the IndiaNIC mentorship staff is always there. Take advice from some of the industry's top professionals. This is dedicated to all the inventors and narrators out there.
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row py-5'>
                <div  className='col-12 px-0'>
                    <div>
                    <img src={DahooksBanner} style={{width:'100%'}}/>
                    </div>
                </div>
            </div>
        </div>
        <Modal open={open} onClose={onCloseModal} center style={{background:'#f2f2f2'}}>
        <div className="container-modal">
          <div className="text-center contact">Contact Us</div>
          <form action="/action_page.php">
            <label className="label-text" for="fname">Name <sup style={{color:'red'}}>*</sup></label>
            <input type="text" onChange={(e)=>{
              setName(e.target.value)
            }} value={name} id="fname" name="firstname" placeholder="Enter Your Name.."/>

            <label className="label-text" for="lname">Email<sup style={{color:'red'}}>*</sup></label>
            <input type="text" onChange={(e)=>{
              setEmail(e.target.value)
            }} value={email} id="lname" name="lastname" placeholder="Enter Your Email.."/>
            <label className="label-text" for="subject">Message<sup style={{color:'red'}}>*</sup></label>
            <textarea onChange={(e)=>{
              if(e.target.value?.length>500){
                setErrr("Messahe should be contain 500 charectors !!");
                setTimeout(() => {
                  setErrr("");
                }, 3000);
                return false
              }
              else{
                setMessage(e.target.value)
              }
            }} id="subject" value={message} name="subject" placeholder="Write something.." style={{height:'100px'}}></textarea>

            {/* <input type="submit" value="Submit"/> */}
            <div className="text-center">
              <button onClick={onSumbit} type="button" className="btn btn-primary" style={{width:'100%'}}>Submit</button>
            </div> 
            <div style={{height:20,color:"red",fontSize:12,marginTop:5}}>{errr?errr:""}</div>
          </form>
        </div>
      </Modal>
    </>
  )
}
export default Career