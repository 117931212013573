import React,{useEffect} from 'react'
import '../Team/Team.css'
import '../customer/customer.css'
import Group from '../../assets/Group.jpg'
import Client from '../../assets/client.png'
import collab from '../../assets/Collab.png'
import quality from '../../assets/Quality.png'
import GopalSir from '../../assets/gopal-sir.png'
import AjaySir from '../../assets/Ajay-sir.png'
import MadhulikaMam from '../../assets/madhulika-mam.png'
import {Helmet} from "react-helmet";

export default function Team() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
    <Helmet>
      <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Our Team - Dahooks | Expert Software Developers & Designers" /><meta property="og:description" content="Our Team - We have a team of professional software Developers, Designers, and QA professionals. Hire our talented minds developers to transform your ideas into reality." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Our Team - Dahooks | Expert Software Developers & Designers" /><meta name="twitter:description" content="Our Team - We have a team of professional software Developers, Designers, and QA professionals. Hire our talented minds developers to transform your ideas into reality." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
      <title>Our Team - Dahooks | Expert Software Developers & Designers</title>
      <link rel="canonical" href="https://dahooks.com/Team" />
    </Helmet>
    <div className='team'>
    <div className='container pt-5'>
        <div className='row'>
            <div className='col-lg-12 mt-5 py-4'>
               
                <div className='contact-banner-heading  p-lg-4  mt-5 '>
                <p className='short-heading ' style={{ textAlign:"center"}}>Our multi-award winning offshore software development firm has been providing excellent solutions since 2012.</p>
                    <h1 style={{lineHeight:"1.5"}}>Our People, Vision, and Values Are Our Treasures.</h1> 
                <p>In addition to being a well-known IT company worldwide, Dahooks is a family<br/> of bright professionals who provide creative solutions to startups, mid-size companies, multinational brands, and even government agencies.</p></div>
              
            </div>
        </div>
    </div>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 px-0'>
          <img src={Group} alt='' width="100%"/>
        </div>
      </div>
    </div>
    <div className=''>
        <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12 order-2 order-lg-1">
                    <div className="service-content">
                        <h1 className='text-center'>Who We Are</h1>
                        <div style={{textAlign:"justify"}}>
                          <p>
                          Dahooks is a family representing unity for more than 12 years of a fruitful journey; it is not an entity. For Dahooksians, success is bringing people's creative ideas to life with our technological know-how; this is what our team wants to be known for!<br/><br/>

Our goal has made Dahooks a leading provider of industry-leading mobility solutions in India and the USA. By utilizing today's and tomorrow's technologies to create new opportunities while maintaining the highest standards of quality, satisfaction, and transparency, the mission is to empower customers and businesses.
                          </p> 
                        </div>
                    </div>
                   
                </div>
                <div className='col-lg-6 col-12 order-1 order-lg-2'>
                <div className="service-content">
                        <h1 className='text-center'>What We Do</h1>
                        <div style={{textAlign:"justify"}}>
                          <p>
                          We now rank among the top IT firms in India and the US thanks to our passion, and we offer the best mobility solutions for developing web and mobile applications. We utilise cutting-edge technologies such as cloud computing, DevOps, the Internet of Things (IoT), AI-ML, AR-VR, voice assistants, and voice skills.<br/><br/>

                          Our research and development team, along with our qualified technical experts, continuously strive to provide a diverse range of IT services and solutions to clients worldwide, giving us great empowerment. Collaborating with our team guarantees that our clients will receive their projects faster and at a significantly reduced development cost.
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
          <div className="contact-banner-heading p-lg-4  mt-4">
            {" "}
            <h1>Belief of Dahooks</h1>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
              <div>
              <img src={Client} alt="" width="60px" />
                <h3 className="mt-2">Client-centric Approach</h3>
                <div className=" bg-white">
                  <div style={{textAlign:"justify"}}>
                    <p>
                    Customers are always at the centre of any business. As a top web and mobile app development company, we consider ourselves more than just our clients. We always begin with your ideas, consider YOUR end customers' viewpoints and pain points, and then work with you to develop a solution that addresses your company's fundamental issues.
                    </p>
                  </div>
                </div>
                </div>
            </div>

            <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
              <img src={collab} alt="" width="60px" />
                <h3 className="mt-2">Effective Collaboration</h3>
                <div className=" bg-white">
                  <div style={{textAlign:"justify"}}>
                    <p>
                    The secret is to communicate. Sometimes we share too much. While it seems intimidating initially, it finally does wonders for our client's company. Our development team uses platforms and mediums that provide efficacy, transparency, and correctness when working with clients a thousand kilometres away.
                    </p>
                  </div>
                </div>
            </div>

            <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
              <img src={quality} alt="" width="60px" />
                <h3 className="mt-2">Quality Delivered in Time</h3>
                <div className=" bg-white">
                  <div style={{textAlign:"justify"}}>
                    <p>
                    Having worked in the offshore IT software development sector for more than 12 years, we have excellent internal frameworks and best-in-class infrastructure that allow us to produce solutions of the highest calibre consistently. After all these years, we know how to duplicate success and avoid failure. Not only do we boast about our quality. We promptly define and provide it.
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
    
        <div className='container'>
        <div className='row'>
            <div className='col-lg-12 my-auto py-4'>
               
                <div className='contact-banner-heading  p-lg-4  mt-4 '>
                    <div className='row'>
                    <h1 style={{ textAlign:"center",lineHeight:1.5}} >In their first year with us, businesses have seen an average 90% boost in revenue thanks to our assistance!</h1>
                    </div> 
            <div className='row mt-5'>
                <div className='col-lg-4 col-12'>
                  <h1 style={{textAlign:"center"}}>3000 +</h1>
                  <div><p>Satisfied Clients Across the Globe</p></div>
                </div>
                <div className='col-lg-4 col-12'>
                  <h1 style={{textAlign:"center"}}>7000 +</h1>
                  <div><p>Projects Delivered Successfully</p></div>
                </div>
                <div className='col-lg-4 col-12'>
                  <h1 style={{textAlign:"center"}}>600 +</h1>
                  <div><p>Experts Under the Same Roof</p></div>
                </div>
            </div>
            </div>
              
            </div>
        </div>
    </div>

    <div className=" background py-4">
         
       <div className='container'>
        <div className='row pt-5'>
            <div className='col-12'>
                <h1 className='text-center mb-5' style={{}}>Our Leaders</h1>
            </div>
        </div>
        <div className='row justify-content-center pb-5'>
          <div className='col-lg-3 col-md-6 col-12'>
                <img src={GopalSir} alt='' width="100%"/>
              <div className='p-3 img-name-bg' style={{borderRadius:"10px",marginTop:"-40px",backgroundColor:"#ffffff7d"}}>
                <div className='text-center mt-4 pt-2' style={{fontSize:"20px",fontWeight:"600",letterSpacing:3}}>GOPAL KAMRA</div>
                <p className='text-center my-2' style={{fontWeight:"600",letterSpacing:3}}>CHIEF OPERATING OFFICER</p>
                <p style={{textAlign:"justify",fontSize:12}}>Bring rish expertise as a COO, adept at orchestrating opreational excellence and leading transformative initiatives. Proven track record in optimizing workflows, fostering interdepartmental collaboration, and achieving opreational milestones</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 mx-5 mt-3 mt-lg-0'>
                <img src={AjaySir} alt='' width="100%"/>
                <div className='p-3 img-name-bg' style={{borderRadius:"10px",marginTop:"-40px",backgroundColor:"#ffffff7d"}}>
                  <div className='text-center mt-4 pt-2' style={{fontSize:"20px",fontWeight:"600",letterSpacing:3}}>AJAY MEHTA</div>
                  <p className='text-center my-2' style={{fontWeight:"600",letterSpacing:3}}>CHIEF FINANCIAL OFFICER</p>
                  <p style={{textAlign:"justify",fontSize:12}}>Profound expertise in risk management, leveraging data
                      insights for fiscal resilience, and driving financial efficiency.
                      Proven track record in guiding mergers, implementing robust
                      fiscal policies, and enhancing profitability through strategic
                      planning.
                  </p>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12 mt-3 mt-lg-0'>
                <img src={MadhulikaMam} alt='' width="100%"/>
                <div className='p-3 img-name-bg' style={{borderRadius:"10px",marginTop:"-40px",backgroundColor:"#ffffff7d"}}>
                  <div className='text-center mt-4 pt-2' style={{fontSize:"20px",fontWeight:"600",letterSpacing:3}}>MADHULIKA</div>
                  <p className='text-center my-2' style={{fontWeight:"600",letterSpacing:3}}>DIRECTOR INFORMATION TECH</p>
                  <p style={{textAlign:"justify",fontSize:12}}>
                    Proficient in leading cross-functional teams,
                    implementing robust IT infrastructure, and ensuring
                    seamless integration of technological solutions for
                    sustained growth.
                  </p>
                </div>
            </div>
            
        </div>
       </div>
        </div>
        {/* <div className='container-fluid'>
          <div className='row py-5'>
            <div className='col-12'><h1 className='text-center pb-3' style={{}}>Our Team</h1></div>
            <div className='col-12 d-flex justify-content-center' style={{flexWrap:'wrap'}}>
              <div className='employee px-0'>
                <img src={EMP22}  alt='' width="100%"/>
              </div>
              <div className='employee px-0'>
                <img src={EMP1}  alt='' width="100%"/>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP3}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP4}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP5}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP7}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP1111}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP8}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP23}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP10}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP11}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP13}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP14}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP15}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP16}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP17}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP18}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP24}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP00}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP19}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP6}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP20}  alt='' width="100%"/></div>
              </div>
              <div className='employee px-0'>
                <div><img src={EMP01}  alt='' width="100%"/></div>
              </div>
              
            </div>
          </div>
        </div> */}

        <div className='background py-5'>
        <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12">
                    <div className="service-content" style={{position: "sticky"}}>
                        <h2 style={{fontSize:"medium"}}>Our Essential Principles</h2>
                        <h1 style={{}}>Provide Software Development Services That Are Dependable and Quality</h1>
                    </div>
                   
                </div>
                <div className='col-lg-6 col-12'>
                <div className="service-content" >
                        <p><strong>Optimum Client Satisfaction</strong></p>
                        <p>
                        Our topmost priority is satisfying our clients. It's an integral part of our company's DNA, and we attribute our success over the years to our loyal clients. This is why we keep client satisfaction at the center of our ecosystem.We don't mind working together to hone our insights and ensure that our products are executed flawlessly. We value the positive feedback we receive from our clients and are constantly working to increase it. We have an insatiable appetite.
                        </p> 

                        <p><strong>Result-oriented Approach</strong></p>
                        <p>
                        We are result-oriented in our approach. The roots of our success are connected to our vision and its execution. From recruitment to delivering quality work, we maintain our values. We hire passionate individuals who have a burning desire to work with us and understand the importance of meeting client expectations to deliver quality results.
                        </p> 

                        <p><strong>Integrity in Everything</strong></p>
                        <p>
                        Integrity is at the core of everything we do. For us, it is as good as following the law, maybe even better. It's about delivering on our commitments, honesty and fairness in business and day-to-day living. We are a family that believes in teamwork with one mantra, which is to die with memories, not dreams. Memories with our people, memories with our clients.
                        </p>

                        <p><strong>Great Place to Work</strong></p>
                        <p>
                        Dahooks is a great place to work because we value our people. We love our employees and embrace diversity of thought, cultures, and people. Nothing feels better than being inspired by the mentors and their success stories. We are compassionate about our priceless assets, our employees. Humans work here, not robots!
                        </p>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className=''>
        <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12 my-auto">
                    <div className="service-content">
                        <h2 style={{fontSize:"medium"}}>Why we’re here?</h2>
                        <h1 style={{}}>What is the reason for which we want to be remembered?</h1>
                    </div>
                   
                </div>
                <div className='col-lg-6 col-12'>
                <div className="service-content">
                        <p>
                        Over the past 11 years, a lot has happened and we have made significant progress. We have grown as a community, formed lifelong friendships, built things and met many people. Although it was a challenging journey with moments of despair and endless weeks, it was all worth it because of the amazing destination we reached. It was truly an adventure!
                        <br/><br/>
                        Success means something different to us now than it used to. While changing a few lives is a noble goal, our ultimate desire is to be recognized for helping others achieve their dreams.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>
  )
}
