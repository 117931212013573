import React,{useEffect} from 'react';
import './App.css';
import Footer from './common/footer/footer';
import Header from './common/headers/header';
import { BrowserRouter , Router, Routes, Route} from 'react-router-dom';
import ContactUs from '../src/views/contact-us/contact-us'
import Home from './views/pages/home/home';
import Career from './views/career/career'
import Case from './views/case-study/case-study'
import WebsiteDev from './views/web/web'
import MobileApps from './views/mobile-apps/mobile-apps'
import Ui from './views/ui-ux/ui-ux'
import Speak from './views/speak-about/speak'
import IOT from './views/IOT/IOT'
import QA from './views/QA/QA'
import DevOps from './views/DevOps/DevOps'
import Marketing from './views/Marketing/Marketing'
import AI from './views/AI/AI'
import Services from './views/services/services'
import Team from './views/Team/Team'
import Work from './views/Work/Work'
import Enterprise from './views/Enterprise/Enterprise'
import Digital_Commerce from './views/digital-commerce/digital-commerce'
import Artificial_Intelligence from './views/Artificial- Intelligence/Artificial'
import Technology_Integration from './views/Technology-Integration/Technology'
import Business_Automation from './views/Business-Automation/Business'
import BI from './views/BI/BI'
import Methodologies from './views/Methodologies/Methodologies'
import Discovery from './views/Discovery/Discovery'
import Healthcare from './views/healthcare/healthcare';
import LegalSec from './views/legal/legal';
import FinanceSec from './views/finance/finance';
import EducationSec from './views/Education/Education';
import InsuranceSec from './views/Insurance/insurance';
import Whatsapp from './assets/whatsapp-green.png'
import ScrollToTop from "react-scroll-to-top";
import Vehicle from './views/case-study/Vehicle Speed/Vehicle';
import Contivio from './views/case-study/SuiteCRM Contivio/Contivio';
import SalesP from './views/case-study/Sales Process/Sales';
import OrderM from './views/case-study/order management/Order';
import OrderInvoice from './views/case-study/order invoice/OrderInvoice';
import GoldMine from './views/case-study/goldmine/Goldmine';
import Telephone from './views/case-study/Telephone intigration/Telephone';
import Inventory from './views/case-study/inventry/Inventry';
import Migration from './views/case-study/migration/Migration';
import ACT from './views/case-study/ACT to SugarCRM Migration/ACT';
import PHPbased from './views/case-study/PHP based/PHPbased';
import SalesAuto from './views/case-study/Sales Process Automation/SalesAuto';
import HealthcareBiling from './views/case-study/Healthcare Biling/HealthcareBiling';
import BillingPlatform from './views/case-study/Billing Platform/BillingPlatform';
import DataMining from './views/case-study/Data Mining/DataMining';
import HealthcareInformation from './views/case-study/Healthcare Information Exchange/HealthcareInformation';
import PortalDev from './views/case-study/Portal Development/PortalDevelopment';
import DataAnalytics from './views/case-study/Data Analytics/DataAnalytics';
import SocialFashion from './views/case-study/Social Fashion Application/SocialFashion';
import AugmentedReality from './views/case-study/Augmented Reality Application/AugmentedReality';
import SmartPayments from './views/case-study/Smart Payments Application/SmartPayments';
import PhotosSharing from './views/case-study/Photos Sharing/PhotosSharing';
import PHPBasedHealthcare from './views/case-study/PHP Based Healthcare/PHPBasedHealthcare';
import CustomHealthcareCRM from './views/case-study/Custom Healthcare CRM/CustomHealthcareCRM';
import HippaComplaint from './views/case-study/Hippa Complaint/HippaComplaint';
import NetFrameworkBasedVehicle from './views/case-study/.NET Framework based Vehicle/.NETFrameworkbasedVehicle';
import MedicationAdministration from './views/case-study/Medication Administration/MedicationAdministration';
import FantastSportsApplication from './views/case-study/Fantasy Sports Application/FantasySportsApplication';
import SaaSBasedSystem from './views/case-study/SaaS Based System/SaaSBasedSystem';
import MusicGuessing from './views/case-study/Music Guessing Game Application/MusicGuessingGameApplication';
import PharmacyUsing from './views/case-study/Pharmacy Management Using HL7/PharmacyManagementUsing';
import Privacy from './views/PrivacyPolicy/Privacy';
function App() {
  
  return (
    
      <BrowserRouter>
        <Header/>
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/contact-us" element={<ContactUs />}/>
            <Route path="/career" element={<Career />}/>
            <Route path="/case" element={<Case />}/> 
            <Route path="/web-dev" element={<WebsiteDev />}/>  
            <Route path="/mobile-apps" element={<MobileApps />}/>            
            <Route path="/ui-ux" element={<Ui/>}/>     
            <Route path="/speak" element={<Speak/>}/>
            <Route path="/iot" element={<IOT/>}/>
            <Route path="/Qa" element={<QA/>}/>
            <Route path="/DevOps" element={<DevOps/>}/>
            <Route path="/Marketing" element={<Marketing/>}/>
            <Route path="/AI" element={<AI/>}/>
            <Route path="/Services" element={<Services/>}/>
            <Route path='/Team' element={<Team/>}/>
            <Route path="/Work" element={<Work/>}/>
            <Route path="/Enterprise" element={<Enterprise/>}/>
            <Route path="/Digital-Commerce" element={<Digital_Commerce/>}/>
            <Route path="/Artificial-Intelligence" element={<Artificial_Intelligence/>}/>
            <Route path="/Technology-Integration" element={<Technology_Integration/>}/>
            <Route path="/Business-Automation" element={<Business_Automation/>}/>
            <Route path="/BI" element={<BI/>}/>
            <Route path="/Methodologies" element={<Methodologies/>}/>
            <Route path="/Discovery" element={<Discovery/>}/>
            <Route path="/Healthcare" element={<Healthcare/>}/>
            <Route path="/Legal" element={<LegalSec/>}/>
            <Route path="/Finance" element={<FinanceSec/>}/>
            <Route path="/Education" element={<EducationSec/>}/>
            <Route path="/Insurance" element={<InsuranceSec/>}/>
            <Route path="/Vehicle" element={<Vehicle/>}/>
            <Route path="/Contivio" element={<Contivio/>}/>
            <Route path="/SalesP" element={<SalesP/>}/>
            <Route path="/OrderM" element={<OrderM/>}/>
            <Route path="/OrderInvoice" element={<OrderInvoice/>}/>
            <Route path="/GoldMine" element={<GoldMine/>}/>
            <Route path="/Telephone" element={<Telephone/>}/>
            <Route path="/Inventory" element={<Inventory/>}/>
            <Route path="/Migration" element={<Migration/>}/>
            <Route path="/ACT" element={<ACT/>}/>
            <Route path="/PHP" element={<PHPbased/>}/>
            <Route path="/SalesAuto" element={<SalesAuto/>}/>
            <Route path="/HealthcareBiling" element={<HealthcareBiling/>}/>
            <Route path="/BillingPlatform" element={<BillingPlatform/>}/>
            <Route path="/DataMining" element={<DataMining/>}/>
            <Route path="/HealthcareInformation" element={<HealthcareInformation/>}/>
            <Route path="/PortalDev" element={<PortalDev/>}/>
            <Route path="/DataAnalytics" element={<DataAnalytics/>}/>
            <Route path="/SocialFashion" element={<SocialFashion/>}/>
            <Route path="/AugmentedReality" element={<AugmentedReality/>}/>
            <Route path="/SmartPayments" element={<SmartPayments/>}/>
            <Route path="/PhotosSharing" element={<PhotosSharing/>}/>
            <Route path="/PHPBasedHealthcare" element={<PHPBasedHealthcare/>}/>
            <Route path="/CustomHealthcareCRM" element={<CustomHealthcareCRM/>}/>
            <Route path="/HippaComplaint" element={<HippaComplaint/>}/>
            <Route path="/NetFrameworkBasedVehicle" element={<NetFrameworkBasedVehicle/>}/>
            <Route path="/MedicationAdministration" element={<MedicationAdministration/>}/>
            <Route path="/FantastSportsApplication" element={<FantastSportsApplication/>}/>
            <Route path="/SaaSBasedSystem" element={<SaaSBasedSystem/>}/>
            <Route path="/MusicGuessing" element={<MusicGuessing/>}/>
            <Route path="/PharmacyUsing" element={<PharmacyUsing/>}/>
            <Route path="/Privacy" element={<Privacy/>}/>
          </Routes>

        <Footer />
      
        <div className="ccw_plugin chatbot d-none d-lg-block" style={{ bottom: 37, right: 20, position: "fixed" }}>
          <div className="ccw_style9 animated no-animation ccw-no-hover-an">
            <a
              target="_blank"
              href="https://web.whatsapp.com/send?phone=917827150289&text=Dahooks"
              className="img-icon-a nofocus"
            >
              <img
                className="img-icon ccw-analytics"
                id="style-9"
                data-ccw="style-9"
                style={{ height: 48 }}
                src={Whatsapp}
                alt="WhatsApp chat"
              />
            </a>
          </div>
        </div>
        <div className="ccw_plugin chatbot d-lg-none" style={{ bottom: 37, right: 20, position: "fixed" }}>
          <div className="ccw_style9 animated no-animation ccw-no-hover-an">
            <a
              target="_blank"
              href="https://wa.me/917827150289"
              className="img-icon-a nofocus"
            >
              <img
                className="img-icon ccw-analytics"
                id="style-9"
                data-ccw="style-9"
                style={{ height: 48 }}
                src={Whatsapp}
                alt="WhatsApp chat"
              />
            </a>
          </div>
        </div>
        <ScrollToTop smooth color="#ffffff" />
      </BrowserRouter>
      
  );
}

export default App