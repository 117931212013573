import React,{useEffect} from 'react'
import '../case-study/case.css'
import Dot_Net from '../../assets/Dot_Net.png'
import CRM_Health from '../../assets/CRM_Health.png'
import CRM_Education from '../../assets/CRM_Education.png'
import CRM_Publication from '../../assets/CRM_INV.png'
import CRM_ETL from '../../assets/CRM_ETL.png'
import CRM_Finance from '../../assets/CRM_Finance.png'
import CRM_Finance1 from '../../assets/CRM_Finance1.png'
import CRM_Inventory from '../../assets/CRM_Inventory.png'
import CRM_Sugar from '../../assets/CRM_Sugar.png'
import ACT_CRM from '../../assets/CRM_ACT.png'
import CRM_PHP from '../../assets/CRM_PHP.png'
import CRM_Media from '../../assets/CRM_Media.png'
import Health1 from '../../assets/Health1.png'
import Health2 from '../../assets/Health2.png'
import Health3 from '../../assets/Health3.png'
import Health4 from '../../assets/Health4.png'
import Health5 from '../../assets/Health5.png'
import Manufacturing from '../../assets/Manufacturing.png'
import Augmented_Reality from '../../assets/Augmented_Reality.png'
import Payment_App from '../../assets/Payment_App.png'
import Social_Fashion from '../../assets/Social_Fashion.png'
import Image_Sharing from '../../assets/Image_Sharing.png'
import Health6 from '../../assets/Health6.png'
import Health7 from '../../assets/Health7.png'
import Health8 from '../../assets/Health8.png'
import Parking from '../../assets/Parking.png'
import Health9 from '../../assets/Health9.png'
import Sports from '../../assets/Sports.png'
import Health10 from '../../assets/Health10.png'
import Music from '../../assets/Music.png'
import Health11 from '../../assets/Health11.png'
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function Case() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    let len=1;
    // document.addEventListener('DOMContentLoaded', function() {
    // var loadMoreBtn = document.querySelector('#load-more');
    // var currentItem = 16;
   // const [temp,settmep]=useState(false);

    // const  hiddenItem= document.getElementById('hiddenItem');
    function displayDiv() {
        if(len>document.getElementsByClassName("show").length)
        {
            len=document.getElementsByClassName("show").length;
        }
        for(let i=0;i<len;i++)
        {
            document.getElementsByClassName("show")[i].style.display="";
        }
        len=len+1;
        console.log('len',len);
        if(len==5){
            document.getElementById("show-more").style.display="none"
        }
            
        //document.getElementById('hiddenItem').style.display = 'block';
      }

//   });

  return (
    <>
    <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Our Projects Case Studies - Dahooks" /><meta property="og:description" content="Dahooks top-notch software development company we have delivered 400+ Projects for global companies. Read our success stories in delivering custom software solutions to diverse businesses." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Our Projects Case Studies - Dahooks" /><meta name="twitter:description" content="Dahooks top-notch software development company we have delivered 400+ Projects for global companies. Read our success stories in delivering custom software solutions to diverse businesses." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>Our Projects Case Studies - Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/case" />
    </Helmet>
    <div className='container pt-5 case-study'>
        <div className='row mt-4'>
            <div className='case-heading'>The stories that describe how <br className='d-none d-lg-block'/>we ship successful products</div>
            <div className='case-text pt-4 pb-5'>Problems are not 'Stop' signs, they are guidelines. Read our success stories in delivering custom <br className='d-none d-lg-block'/>software solutions to diverse businesses.</div>
        </div>
        <div className='row'>
            <div className='d-flex justify-content-between case-tabs border-bottom'>
                <ul className='d-flex p-0 mb-0'>
                    <li className='case-link text-center py-2' style={{paddingLeft:0}}><a href='#/speak'>Popular Brands</a></li>
                    <li className='case-link text-center py-2 border-btm'><a href='#'>Case Studies</a></li>
                    <li className='case-link text-center py-2'><Link to='/speak'>Portfolio</Link></li>
                </ul>
                {/* <ul className='d-flex p-0 mb-0'>
                    <li className='case-link pt-1'>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Type
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className=' pt-md-1'>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Technology
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </li>
                </ul> */}
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/Vehicle' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Dot_Net} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                            <div className='d-flex'>
                                
                                <div className='py-1 px-2 disble-btn'>.NET</div>
                                <div className='py-1 px-2 disble-btn'>INFORMATION TECHNOLOGY</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/Contivio' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Health} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/Salesp' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Education} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>EDUCATION</div>
                                <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/OrderM' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_ETL} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn '>CRM</div>
                                <div className='py-1 px-2 disble-btn '>ETL</div>
                                <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                                <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/OrderInvoice' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Publication} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                                <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/GoldMine' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Finance} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">GoldMine to SuiteCRM Migration</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>FINANCE</div>
                                <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/Telephone' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>FINANCE</div>
                                <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/Inventory' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Inventory Management Using SuiteCRM</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>SERVICE</div>
                                <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/Migration' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Sugar} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / MANUFACTURING / SUGARCRM / SUITECRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">SugarCRM Pro to SuiteCRM Migration</p>
                            <div className='d-flex'>
                                <div className='py-1 px-1 disble-btn'>CRM</div>
                                <div className='py-1 px-1 disble-btn'>MANUFACTURING</div>
                                <div className='py-1 px-1 disble-btn'>SUGARCRM</div>
                                <div className='py-1 px-1 disble-btn'>SUITECRM</div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/ACT' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={ACT_CRM} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SUGARCRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">ACT to SugarCRM Migration</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>SUGARCRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/PHP' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_PHP} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PHP FRAMEWORK / TRADING</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">PHP based Custom CRM for Sales and Inventory Management</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-2 disble-btn'>PHP FRAMEWORK</div>
                                <div className='py-1 px-2 disble-btn'>TRADING</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/SalesAuto' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={CRM_Media} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / MEDIA & ENTERTAINMENT / SUGARCRM</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Sales Process Automation Using On Demand SugarCRM Pro</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>CRM</div>
                                <div className='py-1 px-1 disble-btn'>MEDIA & ENTERTAINMENT</div>
                                <div className='py-1 px-2 disble-btn'>SUGARCRM</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/HealthcareBiling' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health1} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Healthcare Biling Automation Using HL7 and Mirth Connect</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/BillingPlatform' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health2} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Biling Platform and Lab Results Integration Using HL7 and Mirth Connect</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/DataMining' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health3} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE / PHP FRAMEWORK / TABLEAU</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Data Mining, Data Warehousing and Tableau Dashboard Creation</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>PHP FRAMEWORK</div>
                                <div className='py-1 px-2 disble-btn'>TABLEAU</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/HealthcareInformation' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health4} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Customized HL7 Channels for Healthcare Information Exchange (HIE)</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        <div className='row pt-2 pt-md-5 show' style={{display:"none"}}>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left '> 
                <Link to='/PortalDev' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0 "  style={{width: "16rem"}}>
                        <img src={Health5} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE / PHP FRAMEWORK / TABLEAU</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Portal Development and Tableau Dashboards Integration</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>PHP FRAMEWORK</div>
                                <div className='py-1 px-2 disble-btn'>TABLEAU</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/DataAnalytics' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Manufacturing} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>MANUFACTURING / TABLEAU</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Data Analytics and Trending through Tableau Dashboard Creation</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>MANUFACTURING</div>
                                <div className='py-1 px-2 disble-btn'>TABLEAU</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>

             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/AugmentedReality' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Augmented_Reality} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ANDROID / IOS</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Augmented Reality Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>ANDROID</div>
                                <div className='py-1 px-2 disble-btn'>IOS</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/SmartPayments' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0"  style={{width: "16rem"}}>
                        <img src={Payment_App} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ANDROID / IOS</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Smart Payments Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>ANDROID</div>
                                <div className='py-1 px-2 disble-btn'>IOS</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
        </div>
        <div className='row pt-2 pt-md-5 show' style={{display:"none"}}>    
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/SocialFashion' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Social_Fashion} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ANDROID / IOS</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Social Fashion Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>ANDROID</div>
                                <div className='py-1 px-2 disble-btn'>IOS</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/PhotosSharing' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0"  style={{width: "16rem"}}>
                        <img src={Image_Sharing} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ANDROID / IOS</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Photos Sharing and Rating Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>ANDROID</div>
                                <div className='py-1 px-2 disble-btn'>IOS</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/PHPBasedHealthcare' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0"  style={{width: "16rem"}}>
                        <img src={Health6} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE / PHP FRAMEWORK</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">PHP Based Healthcare System With Formulary Check Using Allscripts API</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>PHP FRAMEWORK</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/CustomHealthcareCRM' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health7} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE / PHP FRAMEWORK</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Custom Healthcare CRM With Dental Plan Management Component</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>PHP FRAMEWORK</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
        </div>
        <div className='row pt-2 pt-md-5 show' style={{display:"none"}}>    
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/HippaComplaint' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health8} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE / INFORMATION TECHNOLOGY / PHP FRAMEWORK</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text mb-0" style={{height:70}}>HIPAA Complaint SaaS Based Patient Relationship Management Software</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>INFORMATION TECHNOLOGY</div>
                                {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                                <div className='py-1 px-2 disble-btn mt-1' style={{width:83}}>PHP FRAMEWORK</div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/NetFrameworkBasedVehicle' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Parking} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">.NET Framework based Vehicle Parking Management Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>.NET</div>
                                <div className='py-1 px-2 disble-btn'>INFORMATION TECHNOLOGY</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/MedicationAdministration' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health9} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE / INFORMATION TECHNOLOGY</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Medication Administration Record Exchange Using Mirth HL7</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                <div className='py-1 px-2 disble-btn'>INFORMATION TECHNOLOGY</div>
                                {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/FantastSportsApplication' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Sports} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>IOS</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Fantasy Sports Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>IOS</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
        </div>
        <div className='row pt-2 pt-md-5 show' style={{display:"none"}}>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/SaaSBasedSystem' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health10} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">SaaS Based System for Cardiac Implants Monitoring</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/MusicGuessing' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Music} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>IOS</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Music Guessing Game Application</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>IOS</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
             <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <Link to='/PharmacyUsing' style={{textDecoration:"none"}}>
                    <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                        <img src={Health11} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>HEALTHCARE</div>
                            {/* <h5 className="card-title">HomzMart</h5> */}
                            <p className="card-text">Pharmacy Management Using HL7 Messaging Covering</p>
                            <div className='d-flex'>
                                <div className='py-1 px-2 disble-btn'>HEALTHCARE</div>
                                {/* <div className='py-1 px-2 disble-btn'>My SQL</div>
                                <div className='py-1 px-2 disble-btn'>PHP</div>
                                <div className='py-1 px-2 disble-btn'>React Native</div> */}
                            </div>
                        </div>
                    </div>
                </Link>
             </div>
        </div>

        <div className='button mt-5 mb-5'>
        <button id='show-more' onClick={displayDiv}> Show More </button>
        </div>
         
    
    </div>
    </>
  )
}
