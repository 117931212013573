import React,{useEffect} from "react";
import "../Marketing/Marketing.css";
import Content from "../../assets/Content.png";
import Campaign from "../../assets/Campaign.png";
import Media from "../../assets/Media.png";
import Marketing from "../../assets/Marketing.webp";
import {Helmet} from "react-helmet";

export default function Market() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
    <Helmet>
      <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Digital Marketing Company | Digital Marketing Services in India & USA" /><meta property="og:description" content="Dahooks is the best digital marketing company in Inda & USA We provide high-quality digital marketing services like SEM, SEO, SMO, PPC, and content marketing. Get in touch for a consultation." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Digital Marketing Company | Digital Marketing Services in India & USA" /><meta name="twitter:description" content="Dahooks is the best digital marketing company in Inda & USA We provide high-quality digital marketing services like SEM, SEO, SMO, PPC, and content marketing. Get in touch for a consultation." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
      <title>Digital Marketing Company | Digital Marketing Services in India & USA</title>
      <link rel="canonical" href="https://dahooks.com/Marketing" />
    </Helmet>
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              {" "}
              <h1>
                With the Help of Dahooks' Skilled Digital Marketing Solutions,<br/> Take Your Company to Success
              </h1>
              <p>
                By utilizing our decades of experience in digital marketing services, we enable multinational corporations and businesses to achieve this through individualized campaign creation and management, 360-degree online reputation management, and innovative content.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="contact-banner-heading p-lg-4  mt-4">
            {" "}
            <p className="heading" style={{ color: "red" }}>
            A DIGITAL MARKETING LANDSCAPE EVOLVING
            </p>
            <h1>Our Services as a Premier Digital Marketing Firm</h1>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white custom-card p-3">
              <img src={Content} width="60" />

              <h3 className="heading mt-3 pl-3">Marketing of Content</h3>

              <p>
                We provide unique, interesting, and timely material that speaks to the interests, personalities, and demographics of your target audience.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 mt-3 mt-md-0 d-flex justify-content-center">
            <div className=" bg-white custom-card p-3">
              <img src={Campaign} width="60" />

              <h3 className="heading mt-3 pl-3">PPC Marketing</h3>

              <p>
                Assisting companies in creating, tracking, and optimizing targeted paid campaigns to increase lead generation.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 mt-3 mt-lg-0 d-flex justify-content-center">
            <div className=" bg-white custom-card p-3">
              <img src={Media} width="60" />

              <h3 className="heading mt-3 pl-3">Social Networks</h3>

              <p>
                Centralized SMM services to improve and strengthen brand recognition, customer interaction, and promotions to increase traffic.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="background py-5">
        <div className="container">
        <div className="row ">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading p-lg-4  mt-4">
              {" "}
              <h1>Locate Yourself. Become Heard. Be Successful.</h1>
              <p>
                Since 2012, Dahooks has provided transparent methodology—which consists of strategic advising, brainstorming, creative planning, implementation, reporting, and analysis—to assist multinational corporations in increasing target conversions.
              </p>
              <p>
                We are able to provide you a comprehensive range of services because of the expertise of our digital marketing specialists. We're prepared to collaborate with you on marketing!
              </p>
              <img className="kl_img" src={Marketing} alt="" width="100%" />
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
