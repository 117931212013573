import React,{useEffect} from "react";
import "../mobile-apps/mobile-apps.css";
import workshop from "../../assets/workshop.png";
import Project_Exe from "../../assets/project execution.png";
import Remote_Team from "../../assets/remote team.png";
import Project_Review from "../../assets/project-review.png";
import MobEnterprise from "../../assets/MobEnterprise.png";
import Consumers from "../../assets/consumers.png";
import Business from "../../assets/business-icon.png";
import MobCross from "../../assets/Mobile_Cross.jpg";
import {Helmet} from "react-helmet";


export default function Methodologies() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Helmet>
        <meta property="locale" content="en_US" />
        <meta property="type" content="Page" /><meta property="title" content="fermg oirg virmt " /><meta property="description" content="Preet Shartma " />
        <meta property="url" content="https://dahooks.com/web-dev" />
        <meta property="site_name" content="DAHOOKS" />
        <meta property="image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <meta property="image:width" content="1500" />
        <meta property="image:height" content="800" />
        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Software Development Methodologies - Dahooks" /><meta name="twitter:description" content="Dahooks as a reputable software development firm we are well-versed in software development methodologies like Discovery Workshop, Project Execution, Remote Team and Project Review. Let’s discuss your project." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /> */}
        <title>Software Development Methodologies - Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/Methodologies" />
      </Helmet>
      <div className="container  pt-5">
        <div className="row ">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              {" "}
              <h1>
              Methods of Work in Software Development
              </h1>
              <p>
              Since we have stood tall since 2012, we have gained knowledge from both mistakes and successes. It made it easier for us to determine what you, the client, actually need. Are you only in need of a software product? No, that is not all that you need. Before you interact, get a good understanding of the firm and its details.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-3">
        <div className="row ">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading p-lg-4  mt-4">
              {" "}
              <p style={{color:"#f4990b"}}>
              OUR WORK METHODOLOGY
              </p>
              <h1>
              Learn More On
              </h1>
            
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="card  p-4">
                     
                        <img src={workshop} width="60px"/>
                       
                        <h1 style={{textAlign:"left",fontSize:"28px"}}>Discovery Workshop</h1>
                        <p style={{textAlign:"left"}}>Idea transformation into a quantifiable scope using practical UI/UX design.</p>
                        
                    </div>
                </div>
                <div className="col-6">
                    <div className="card p-4">
                    <img src={Project_Exe} width="60px"/>
                    <h1 style={{textAlign:"left",fontSize:"28px"}}>Project Execution</h1>
                    <p style={{textAlign:"left"}}>Learn how we strike a balance between the three important factors: cost, time, and scope.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="card p-4 mt-4">
                    <img src={Remote_Team} width="60px"/>
                    <h1 style={{textAlign:"left",fontSize:"28px"}}>Remote Team</h1>
                    <p style={{textAlign:"left"}}>From a knowledgeable group operating remotely under your direction.</p>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card p-4 mt-4" >
                    <img src={ Project_Review} width="60px"/>
                   
                    <h1 style={{textAlign:"left",fontSize:"28px"}}>Project Review</h1>
                    <p style={{textAlign:"left"}}>Learn how we strike a balance between the three important factors: cost, time, and scope.</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" background py-4">
          <div className="container">
            <div className="row">
              <div className="contact-banner-heading px-lg-4">
                {" "}
                <h1>Why is it crucial to you and me?</h1>
                <p>
                  Dahooks' working credo has always been centered on client satisfaction and quality. Over two decades of expertise and stability as a reputable software development firm has taught us a lot about the customer's initial requirements. In most circumstances, the most prevalent inquiry is, "What will happen and how?"
                </p>
                <p>
                The information provided here will assist you in understanding what is required for your business idea, how we operate, and how to save time by gathering the necessary information ahead of time. Please keep in mind that we are just as invested in your business idea as you are!
                 </p>
              </div>
            </div>
            
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
              <img className="kl_img" src={MobCross} alt="" width="100%" />
              </div>
            </div>
            </div>
        </div>
        <div className="container">
          <div className="contact-banner-heading p-lg-4  mt-4">
            {" "}
            <h1>We aid you in developing apps for</h1>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
              <div>
              <img src={MobEnterprise} alt="" width="60px" />
                <h3 className="mt-2">Enterprise</h3>
                <div className=" bg-white">
                  <p>
                    In order to streamline IT frameworks and processes with a mobile roadmap that is in line with enterprise scenarios, policies, and existing systems, our collaborative consulting on mobile strategy acts as a catalyst.
                  </p>
                </div>
                </div>
            </div>

            <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
              <img src={Business} alt="" width="60px" />
                <h3 className="mt-2">Business</h3>
                <div className=" bg-white">
                  <p>
                    To help businesses react swiftly to changes, we outline their use cases and demands. We assist your company in implementing procedures, instantaneous information availability, and interdepartmental communication.
                  </p>
                </div>
            </div>

            <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
              <img src={Consumers} alt="" width="60px" />
                <h3 className="mt-2">Consumers</h3>
                <div className=" bg-white">
                  <p>
                    Our mobile professionals work with companies, brands, and community organizations to create scalable mobile application solutions that are intended to be used often throughout the day by customers worldwide.
                  </p>
                </div>
            </div>
          </div>
        </div>
      
    </>
  );
}
