import React,{useEffect} from "react";
import "../digital-commerce/digital-commerce.css";
import "./finance.css"
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Technology_Integration from "../../assets/finance.jpg";
import Improved_Efficiency from "../../assets/Improved Efficiency and Productivity.png";
import Enhanced_Customer from "../../assets/Enhanced Customer Experience.png";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Teamwork from "../../assets/teamwork.png";
import Flexibility from "../../assets/flexibility.png";
import Decision_Making from "../../assets/decision-making.png";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM from "../../assets/crm.png";
import Img_1 from '../../assets/ind-finance-slide-img-1.jpg'
import Img_2 from '../../assets/ind-finance-slide-img-2.jpg'
import Img_3 from '../../assets/ind-finance-slide-img-3.jpg'
import Img_4 from '../../assets/ind-finance-slide-img-4.jpg'
import Img_5 from '../../assets/ind-finance-slide-img-5.jpg'
import Img_6 from '../../assets/ind-finance-slide-img-6.jpg'
import Cost from '../../assets/cost.png'
import Quality from '../../assets/quality1.png'
import Communication from '../../assets/communication.png'
import Flex from '../../assets/flexibility.png'
import Security from '../../assets/security.png'
import {Helmet} from "react-helmet";

// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {

  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: "18px", cursor: "pointer" }}
      >
        <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};



export default function FinanceSec() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
    <Helmet>
      <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Banking & Finance Software Development Company In USA, India| Dahooks" /><meta property="og:description" content="Dahooks is a top-rated Finance and banking Software Development Company. We offers finance software solutions for web and mobile platform. Hire our experts Finance Software Developer today!" /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Banking & Finance Software Development Company In USA, India| Dahooks" /><meta name="twitter:description" content="Dahooks is a top-rated Finance and banking Software Development Company. We offers finance software solutions for web and mobile platform. Hire our experts Finance Software Developer today!" /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
      <title>Banking & Finance Software Development Company In USA, India| Dahooks</title>
      <link rel="canonical" href="https://dahooks.com/Finance" />
    </Helmet>
    <div className="finance">
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-5">
            <div className=" p-lg-4  mt-5">
              <h1 className="mb-0 text-center" style={{ color: "#FFB800", }}>
              Financial Industry IT Services
              </h1>

              <div
                className="headings"
                style={{
                  fontSize: "28px",
                  textAlign: "center",
                  lineHeight: "70px",
                  fontWeight:700
                }}
              >
                Modern Tech Solutions for Transformation
              </div>
              <p className="text-center">
              The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions. Financial innovations such as algorithmic trading, mobile banking, and others have transformed the sector and increased accessibility to financial services for consumers. A more streamlined and efficient financial ecosystem has been made possible by these advancements, which have caused a change in how consumers engage with financial institutions.


              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={Technology_Integration} width="100%" />
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
            <div className="col-12 text-center py-5">
                <div className="headings pb-4" style={{ color: "rgb(244, 153, 11)"}}>KEY INSIGHTS</div>
                <h1 style={{}}>Finance Industry Transformation over the years</h1>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4 col-12 text-center">
                <div>
                    <h1 style={{}}>$460 B</h1>
                    <div>The global fintech market is expected to reach in 2025 by</div>
                </div>
            </div>
            <div className="col-lg-4 col-12 text-center">
                <div>
                    <h1 style={{}}>89%</h1>
                    <div>of financial companies have been impacted by disruptive technologies like AI, Big Data, etc.</div>
                </div>
            </div>
            <div className="col-lg-4 col-12 text-center">
                <div>
                    <h1 style={{}}>77%</h1>
                    <div>of consumers use mobile banking apps and and 45% prefer it over visiting a physical branch.</div>
                </div>
            </div>
        </div>
      </div> */}

      <div className="container pb-5">
          <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className=" p-lg-4  mt-4">
              <p className="text-center" style={{ color: "#f4990b" }}>
              DEMAND
              </p>
              <h1 className="text-center" style={{}}>
              Finance's Significance Businesses should use technology to modernize their operations
                </h1>
            </div>
          </div>
          {/* web */}
          <div className="row d-none d-lg-block">
          <Slider {...settings1}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_1} alt ='' width="100%"/>
              <h3 className="mt-3">Fulfill changing demands from customers</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions need to embrace new technology in order to fulfill the expectations of today's consumers, who demand flawless digital experiences.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_2} alt ='' width="100%"/>
              <h3 className="mt-3">Boost client support</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions can provide their clients quicker, more individualized, and more convenient services by implementing technology.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_3} alt ='' width="100%"/>
              <h3 className="mt-3">Reach new markets</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions may boost their revenue, enter new markets, and grow their customer base with the aid of digital technologies.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_4} alt ='' width="100%"/>
              <h3 className="mt-3">Boost client support</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions can provide their clients quicker, more individualized, and more convenient services by implementing technology.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_5} alt ='' width="100%"/>
              <h3 className="mt-3">Cut expenses</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              By automating formerly laborious chores and optimizing procedures, technology can help cut expenses.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_6} alt ='' width="100%"/>
              <h3 className="mt-3">Reach new markets</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions may enhance their revenue streams, enter new markets, and grow their customer base with the aid of digital technologies.
              </p>
           </div>
            </div>
            </Slider>
          </div>

          <div className="row d-none d-md-block d-lg-none">
          <Slider {...settings}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_1} alt ='' width="100%"/>
              <h3 className="mt-3">Fulfill changing demands from customers</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions need to embrace new technology in order to fulfill the expectations of today's consumers, who demand flawless digital experiences.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_2} alt ='' width="100%"/>
              <h3 className="mt-3">Boost client support</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions can provide their clients quicker, more individualized, and more convenient services by implementing technology.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_3} alt ='' width="100%"/>
              <h3 className="mt-3">Reach new markets</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions may boost their revenue, enter new markets, and grow their customer base with the aid of digital technologies.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_4} alt ='' width="100%"/>
              <h3 className="mt-3">Boost client support</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions can provide their clients quicker, more individualized, and more convenient services by implementing technology.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_5} alt ='' width="100%"/>
              <h3 className="mt-3">Cut expenses</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              By automating formerly laborious chores and optimizing procedures, technology can help cut expenses.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_6} alt ='' width="100%"/>
              <h3 className="mt-3">Reach new markets</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions may enhance their revenue streams, enter new markets, and grow their customer base with the aid of digital technologies.
              </p>
           </div>
            </div>
            </Slider>
          </div>

          {/* mobile */}
          <div className="row d-md-none">
          <Slider {...settings2}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_1} alt ='' width="100%"/>
              <h3 className="mt-3">Fulfill changing demands from customers</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions need to embrace new technology in order to fulfill the expectations of today's consumers, who demand flawless digital experiences.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_2} alt ='' width="100%"/>
              <h3 className="mt-3">Boost client support</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions can provide their clients quicker, more individualized, and more convenient services by implementing technology.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_3} alt ='' width="100%"/>
              <h3 className="mt-3">Reach new markets</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions may boost their revenue, enter new markets, and grow their customer base with the aid of digital technologies.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_4} alt ='' width="100%"/>
              <h3 className="mt-3">Boost client support</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions can provide their clients quicker, more individualized, and more convenient services by implementing technology.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_5} alt ='' width="100%"/>
              <h3 className="mt-3">Cut expenses</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              By automating formerly laborious chores and optimizing procedures, technology can help cut expenses.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_6} alt ='' width="100%"/>
              <h3 className="mt-3">Reach new markets</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Financial institutions may enhance their revenue streams, enter new markets, and grow their customer base with the aid of digital technologies.
              </p>
           </div>
            </div>
            </Slider>
          </div>
          </div>
        </div>

        <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12"  >
                      <div className="tagline">
                        <p style={{color:"#f4990b"}}>OUR INTERNET SERVICE SPECIALIZING IN FINANCE</p>
                       <h1>Regarding banks, investment companies, and insurance providers.</h1>
                        </div>
                 
                </div>
                <div className='col-lg-6 col-12'>
                <div className="service-content" >
                <p><strong>Software for accounting</strong></p>
                <p>Through automation and error reduction, this software can simplify financial management tasks such as managing accounts payable and accounts receivable.</p>

                <p><strong>Customers Relationship Management (CRM)</strong></p>
                <p>This type of software may be utilized by financial companies to monitor transactions, handle client interactions, and discover potential new business opportunities.</p>

                <p><strong>Software for data analytics</strong></p>
                <p>With the use of this kind of software, financial institutions may examine vast volumes of data and discover patterns in consumer behavior, investment performance, and market movements.</p>

                <p><strong>Software for risk management</strong></p>
                <p>Financial institutions can utilize this software to oversee and manage risk, which may include assessing market and credit risks, as well as ensuring compliance with regulations.</p>

                
                <p><strong>Software for managing investments</strong></p>
                <p>Investment firms, pension funds, and other financial institutions can utilize this type of software to keep track of their performance and manage their portfolios effectively.</p>

                <p><strong>Software for financial planning</strong></p>
                <p>Financial advisors have the ability to create personalized financial plans for their clients utilizing this software, which encompasses estate, tax, and retirement planning.</p>

                <p><strong>Software for managing compliance</strong></p>
                <p>Financial institutions can utilize this software to ensure compliance with regulations such as the Sarbanes-Oxley Act and the Dodd-Frank Act.</p>

                <p><strong>Software for managing documents</strong></p>
                <p>This kind of software can be used by financial institutions to handle and arrange crucial papers including contracts, agreements, and client information.</p>
                
                <p><strong>Software for automating workflows</strong></p>
                <p>This kind of software can be used by financial institutions to automate time-consuming jobs and optimize procedures, like processing loan applications and welcoming new customers.</p>
                
                
                    </div>
                </div>
            </div>
        </div>
        

        <div className="background py-5">
          <div className="container-fluid ">
        <div className="head" style={{textAlign:"center"}}>
        <div style={{color:"#f4990b",fontSize:"18px"}}>FOCUS AREAS</div>
        <h1 className="mb-5" style={{}}>Formulating efficient tactics for Financial Institutions<br/> Important places to pay attention to.</h1>
           
        </div>
        <div className="row">
        <Slider className="d-none d-md-block d-lg-none" {...settings}>
        <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Health} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Enterprise Solutions</p>
                            <p className="card-text">Accept comprehensive solutions to manage various business processes and bring together all relevant departments on one platform.</p>
                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Legal} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">BI and Analytics</p>
                            <p className="card-text">Make use of analytics tools and data visualization dashboards to leverage data for market trends, customer insights, and predictive modeling.</p>
                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Education} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Artificial Intelligence</p>
                            <p className="card-text">For better operations, fraud protection, automated customer service, tailored financial advice, and pattern recognition, take advantage of AI's ML, NLP, and robotics capabilities.</p>
                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={Finance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Technology Integration</p>
                            <p className="card-text">Integrate invoicing software, communication tools, and customer support platforms to create a more efficient and productive tech ecosystem.</p>
                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
               
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Digital Commerce</p>
                            <p className="card-text">To increase revenue and expand your market presence, use digital commerce tactics including digital wallets, mobile technology, and online marketplaces.</p>
                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={CRM} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Business Automation</p>
                            <p className="card-text">To increase productivity and lower error rates, automate manual and repetitive tasks including document management, loan processing, and account opening.</p>
                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>

            <Slider className="d-none d-lg-block" {...settings3}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Health} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Enterprise Solutions</p>
                            <p className="card-text">Accept comprehensive solutions to manage various business processes and bring together all relevant departments on one platform.</p>
                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Legal} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">BI and Analytics</p>
                            <p className="card-text">Make use of analytics tools and data visualization dashboards to leverage data for market trends, customer insights, and predictive modeling.</p>
                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Education} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Artificial Intelligence</p>
                            <p className="card-text">For better operations, fraud protection, automated customer service, tailored financial advice, and pattern recognition, take advantage of AI's ML, NLP, and robotics capabilities.</p>
                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={Finance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Technology Integration</p>
                            <p className="card-text">Integrate invoicing software, communication tools, and customer support platforms to create a more efficient and productive tech ecosystem.</p>
                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
               
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Digital Commerce</p>
                            <p className="card-text">To increase revenue and expand your market presence, use digital commerce tactics including digital wallets, mobile technology, and online marketplaces.</p>
                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={CRM} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Business Automation</p>
                            <p className="card-text">To increase productivity and lower error rates, automate manual and repetitive tasks including document management, loan processing, and account opening.</p>
                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>

            <Slider className="d-md-none" {...settings2}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Health} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Enterprise Solutions</p>
                            <p className="card-text">Accept comprehensive solutions to manage various business processes and bring together all relevant departments on one platform.</p>
                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Legal} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">BI and Analytics</p>
                            <p className="card-text">Make use of analytics tools and data visualization dashboards to leverage data for market trends, customer insights, and predictive modeling.</p>
                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Education} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Artificial Intelligence</p>
                            <p className="card-text">For better operations, fraud protection, automated customer service, tailored financial advice, and pattern recognition, take advantage of AI's ML, NLP, and robotics capabilities.</p>
                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={Finance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Technology Integration</p>
                            <p className="card-text">Integrate invoicing software, communication tools, and customer support platforms to create a more efficient and productive tech ecosystem.</p>
                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
               
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Digital Commerce</p>
                            <p className="card-text">To increase revenue and expand your market presence, use digital commerce tactics including digital wallets, mobile technology, and online marketplaces.</p>
                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={CRM} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Business Automation</p>
                            <p className="card-text">To increase productivity and lower error rates, automate manual and repetitive tasks including document management, loan processing, and account opening.</p>
                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>
            </div>
            </div>
        </div>
        <div className="container">
        <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="  p-lg-4  mt-4 ">
              <div className="row">
                <p className="heading text-center"  style={{color: "rgb(244, 153, 11)"}}>KEY BENEFITS</p>
                <h1 className="mt-0 text-center" style={{}}>Everything you need to learn online,<br/> anytime, anywhere</h1>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                        <img src={Improved_Efficiency} width="60px"/>
                        <h3 className="mt-3"><strong>Enhanced Effectiveness</strong></h3>
                        <p>Organizations can accomplish things more quickly and with fewer errors by using software solutions that automate and simplify operations.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                        <img src={Enhanced_Customer} width="60px"/>
                        <h3 className="mt-3"><strong>Enhanced Precision</strong></h3>
                        <p>In the financial industry, where errors can have grave repercussions, software solutions are essential for reducing errors and increasing accuracy.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                        <img src={Teamwork} width="60px"/>
                        <h3 className="mt-3"><strong>Better Ability to Make Decisions</strong></h3>
                        <p>Software solutions provide real-time analytics and data, enhancing decision-making and pointing up opportunities for businesses.</p>
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start mt-3">
                    <div>
                        <img src={Cost_Savings} width="60px"/>
                        <h3 className="mt-3"><strong>Improve the Client Experience</strong></h3>
                        <p>Software solutions enhance client experiences by facilitating more individualized services and quicker reaction times.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start mt-3">
                    <div>
                        <img src={Flexibility} width="60px"/>
                        <h3 className="mt-3"><strong>Improved Information Organizing</strong></h3>
                        <p>Software solutions efficiently handle massive data sets, guaranteeing current and easily accessible information.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start mt-3">
                    <div>
                        <img src={Decision_Making} width="60px"/>
                        <h3 className="mt-3"><strong>Lower Expenses</strong></h3>
                        <p>Software solutions help businesses become more profitable by increasing productivity, decreasing errors, saving money and time.</p>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        </div>
        
        <div className="container">
            <div className="row mb-5">
                <div className="col-12 text-center">
                    <p className="heading " style={{color:"rgb(244, 153, 11)"}}>WHY CHOOSE US?</p>
                    <h1 className="mb-5" style={{}}>Let Dahooks handle your finance technology<br/>outsourcing needs</h1>
                </div>
                <div className="col-md-4 col-12 mt-3 mt-md-0">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Security} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Expertise</strong></div>
                      <p>Tech professionals who understand rules and specifications and provide customized solutions for all of your requirements.</p>
                  </div>
              </div>
              
              <div className=" col-md-4 col-12 mt-3 mt-md-0">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Quality} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Quality</strong></div>
                      <p>A track record of meeting deadlines, producing high-quality work, and offering clients the finest options.</p>
                  </div>
              </div>
              <div className="col-md-4 col-12 mt-3 mt-md-0">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Security} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Security</strong></div>
                      <p className="pb-4 mb-2">We put a high priority on data privacy and safeguard customer data with robust security procedures.</p>
                  </div>
              </div>
              <div className=" col-md-4 col-12 mt-3">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Communication} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Communication</strong></div>
                      <p>Communicative responsiveness and close collaboration with clients to comprehend their needs.</p>
                  </div>
              </div>
              <div className="col-md-4 col-12 mt-3">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Flex} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Flexibility</strong></div>
                      <p>We make adjustments to our solutions in response to our clients' evolving needs.</p>
                  </div>
              </div>
              
              <div className="col-md-4 col-12 mt-3">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Cost} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Cost-effectiveness</strong></div>
                      <p className="pb-4 mb-2">Economical alternatives that don't sacrifice knowledge or quality.</p>
                  </div>
              </div>
            </div>
        </div>
    </div>
    </>
  );
}
