import React,{useEffect} from "react";
import "../digital-commerce/digital-commerce.css";
import "./healthcare.css"
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Technology_Integration from "../../assets/healthcare.jpg";
import Improved_Efficiency from "../../assets/Improved Efficiency and Productivity.png";
import Enhanced_Customer from "../../assets/Enhanced Customer Experience.png";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Teamwork from "../../assets/teamwork.png";
import Flexibility from "../../assets/flexibility.png";
import Decision_Making from "../../assets/decision-making.png";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM from "../../assets/crm.png";
import Img_1 from '../../assets/ind-healthcare-slide-1.jpg'
import Img_2 from '../../assets/ind-healthcare-slide-2.jpg'
import Img_3 from '../../assets/ind-healthcare-slide-3.jpg'
import Img_4 from '../../assets/ind-healthcare-slide-4.jpg'
import Img_5 from '../../assets/ind-healthcare-slide-5.jpg'
import Img_6 from '../../assets/ind-healthcare-slide-6.jpg'
import Cost from '../../assets/cost.png'
import Quality from '../../assets/quality1.png'
import Communication from '../../assets/communication.png'
import Flex from '../../assets/flexibility.png'
import Security from '../../assets/security.png'
import Health1 from '../../assets/healthcare-1.png'
import {Helmet} from "react-helmet";


// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: "18px", cursor: "pointer" }}
      >
        <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};



export default function Healthcare() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
    <Helmet>
      <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="IT Solutions Company for Healthcare Industry in USA & India - Dahooks" /><meta property="og:description" content="Dahooks provides healthcare IT services and solutions in india & USA. We offers best-in-class healthcare software solutions to various healthcare startups, companies, and hospitals. Get a free quote now!" /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="IT Solutions Company for Healthcare Industry in USA & India - Dahooks" /><meta name="twitter:description" content="Dahooks provides healthcare IT services and solutions in india & USA. We offers best-in-class healthcare software solutions to various healthcare startups, companies, and hospitals. Get a free quote now!" /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
      <title>IT Solutions Company for Healthcare Industry in USA & India - Dahooks</title>
      <link rel="canonical" href="https://dahooks.com/Healthcare" />
    </Helmet>
    <div className="healthcare">
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-4">
            <div className=" p-lg-4  mt-5">
              <h1 className="mb-0 text-center" style={{ color: "#FFB800", }}>
              Company for Healthcare IT Solutions and Services
              </h1>

              <div
                className="headings"
                style={{
                  fontSize: "28px",
                  textAlign: "center",
                  lineHeight: "70px",
                  fontWeight:700
                }}
              >
                Modern Tech Solutions for Transformation
              </div>
              <p className="text-center">
              Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading and mobile banking have dramatically transformed the industry, making financial services more accessible to consumers and consequently dramatically changing the way consumers interact with financial institutions, creating finance a simple and productive environment
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={Technology_Integration} width="100%" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
            <div className="col-12 text-center py-5">
                <div className="headings" style={{ color: "rgb(244, 153, 11)"}}>KEY INSIGHTS</div>
                <h1 style={{}}>Healthcare Transformation over the years</h1>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
              <img src={Health1} width={"100%"}/>
            </div>
        </div>
      </div>

      <div className="container pb-5">
          <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className=" p-lg-4  mt-4">
              <p className="text-center" style={{ color: "#f4990b" }}>
              DEMAND
              </p>
              <h1 className="text-center" style={{}}>
              Examining Technology's Role in Modernizing Healthcare Organizations.
                </h1>
            </div>
          </div>
          {/* web */}
          <div className="row d-none d-lg-block">
          <Slider {...settings1}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_1} alt ='' width="100%"/>
              <h3 className="mt-3">Growing need for medical services</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Through increased productivity and operational simplification, technology can assist healthcare organizations in meeting the increasing demand.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4  d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_2} alt ='' width="100%"/>
              <h3 className="mt-3">Rising medical expenses</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Technology lowers waste, maximizes resource utilization, and automates healthcare procedures to save money.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_3} alt ='' width="100%"/>
              <h3 className="mt-3">Heightened rivalry</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
              Modern technology helps healthcare companies compete with urgent care, retail clinics, and telemedicine.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_4} alt ='' width="100%"/>
              <h3 className="mt-3">Changing laws</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Technology solutions meet changing healthcare laws and make compliance easier by automating processes and delivering real-time information.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_5} alt ='' width="100%"/>
              <h3 className="mt-3">Better results for patients</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Through improved access, communication, and collaboration in the healthcare industry, tech modernization enhances patient outcomes.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_6} alt ='' width="100%"/>
              <h3 className="mt-3">Improved encounter with patients</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                With online scheduling that makes healthcare accessible, easy, and personalized, tech modernization fulfills patient expectations.
              </p>
           </div>
            </div>
            </Slider>
          </div>

          <div className="row d-none d-md-block d-lg-none">
          <Slider {...settings}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_1} alt ='' width="100%"/>
              <h3 className="mt-3">Growing need for medical services</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Through increased productivity and operational simplification, technology can assist healthcare organizations in meeting the increasing demand.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4  d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_2} alt ='' width="100%"/>
              <h3 className="mt-3">Rising medical expenses</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Technology lowers waste, maximizes resource utilization, and automates healthcare procedures to save money.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_3} alt ='' width="100%"/>
              <h3 className="mt-3">Heightened rivalry</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Modern technology helps healthcare companies compete with urgent care, retail clinics, and telemedicine.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_4} alt ='' width="100%"/>
              <h3 className="mt-3">Changing laws</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Technology solutions meet changing healthcare laws and make compliance easier by automating processes and delivering real-time information.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_5} alt ='' width="100%"/>
              <h3 className="mt-3">Better results for patients</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Through improved access, communication, and collaboration in the healthcare industry, tech modernization enhances patient outcomes.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_6} alt ='' width="100%"/>
              <h3 className="mt-3">Improved encounter with patients</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                With online scheduling that makes healthcare accessible, easy, and personalized, tech modernization fulfills patient expectations.
              </p>
           </div>
            </div>
            </Slider>
          </div>

          {/* mobile */}
          <div className="row d-md-none">
          <Slider {...settings2}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_1} alt ='' width="100%"/>
              <h3 className="mt-3">Growing need for medical services</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Through increased productivity and operational simplification, technology can assist healthcare organizations in meeting the increasing demand.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4  d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_2} alt ='' width="100%"/>
              <h3 className="mt-3">Rising medical expenses</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Technology lowers waste, maximizes resource utilization, and automates healthcare procedures to save money.
              </p>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_3} alt ='' width="100%"/>
              <h3 className="mt-3">Heightened rivalry</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Modern technology helps healthcare companies compete with urgent care, retail clinics, and telemedicine.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_4} alt ='' width="100%"/>
              <h3 className="mt-3">Changing laws</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Technology solutions meet changing healthcare laws and make compliance easier by automating processes and delivering real-time information.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_5} alt ='' width="100%"/>
              <h3 className="mt-3">Better results for patients</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                Through improved access, communication, and collaboration in the healthcare industry, tech modernization enhances patient outcomes.
              </p>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Img_6} alt ='' width="100%"/>
              <h3 className="mt-3">Improved encounter with patients</h3>
              <p className="service-points" style={{textAlign:"justify"}}>
                With online scheduling that makes healthcare accessible, easy, and personalized, tech modernization fulfills patient expectations.
              </p>
           </div>
            </div>
            </Slider>
          </div>
          </div>
        </div>

        <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12"  >
                      <div className="tagline">
                        <p style={{color:"#f4990b"}}>OUR SOFTWARE AND IT RELATED TO HEALTHCARE</p>
                       <h1>For telemedicine companies, fitness consultants, hospitals, and labs.</h1>
                        </div>
                 
                </div>
                <div className='col-lg-6 col-12'>
                <div className="service-content" >
                <p><strong>Health Information Technology (EHR)</strong></p>
                <p>There is a platform that is digital in nature, and it helps healthcare professionals to share and access patient records. This platform enhances teamwork, reduces errors, and saves time.</p>

                <p><strong>Software for medical billing and coding</strong></p>
                <p>An automated medical billing system can decrease errors, increase accuracy, and expedite the payment processing procedure.</p>

                <p><strong>Software for telemedicine</strong></p>
                <p>A new telemedicine system enhances accessibility to healthcare and reduces costs by facilitating remote communication between patients and medical professionals.</p>

                <p><strong>Software for managing inventories</strong></p>
                <p>There is software that assists healthcare institutions in managing their pharmaceuticals and supplies. This program aims to reduce waste and optimize inventory levels.</p>

                
                <p><strong>Software for scheduling patients</strong></p>
                <p>Users can utilize a digital platform to schedule appointments online, which has the potential to expedite the scheduling process, minimize waiting times, and enhance productivity.</p>

                <p><strong>Exchange of health information (HIE)</strong></p>
                <p>A system facilitates the secure exchange of patient information between healthcare organizations. This system could improve care coordination and reduce the frequency of redundant medical tests and procedures.</p>

                <p><strong>Software for clinical decision support</strong></p>
                <p>Medical professionals can access real-time clinical advice and recommendations from a system that can enhance the accuracy of diagnosis and treatment plans.</p>

                <p><strong>Software for patient monitoring</strong></p>
                <p>Healthcare professionals could enhance patient outcomes and reduce hospital readmissions by using a system that monitors patient data remotely.</p>
                
                <p><strong>Software for patient engagement</strong></p>
                <p>A system that enables patients to manage their care plans, contact healthcare providers, and access their health data can increase patient happiness and participation.</p>
                
                <p><strong>Software for analytics and reporting</strong></p>
                <p>Our healthcare information system offers comprehensive and current analysis, providing organizations with the tools to optimize performance. This system empowers healthcare professionals to make informed choices, improving patient care and outcomes.</p> 
                    </div>
                </div>
            </div>
        </div>
        

        <div className="background py-5">
          <div className="container-fluid">
        <div className="head" style={{textAlign:"center"}}>
        <p className="text-center" style={{color:"#f4990b"}}>FOCUS AREAS</p>
        <h1 className="mb-5" style={{}}>Creating strategies that work for healthcare organizations: Important points to remember.</h1>
           
        </div>
        <div className="row">
        <Slider className="d-none d-md-block d-lg-none" {...settings}>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Health} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Enterprise Solutions</p>
                            <p className="card-text">Enterprise-level solutions that improve operational effectiveness and streamline data administration include Customer Relationship administration (CRM) systems, Electronic Health Records (EHRs), and other software tools.</p>
                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Legal} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Artificial Intelligence</p>
                            <p className="card-text">AI improves diagnosis, predicts patient outcomes for individualized treatment, and automates tasks, so empowering the healthcare industry. NLP, chatbots, and predictive analytics are a few AI technologies that healthcare organizations might investigate.</p>
                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Education} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">BI and Analytics</p>
                            <p className="card-text">By using business intelligence and analytics technologies to evaluate patient data and find insightful information through dashboards and data visualization, healthcare organizations may improve decision-making.</p>
                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={Finance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Technology Integration</p>
                            <p className="card-text">To increase overall efficiency and expedite procedures, this strategy integrates a variety of technology, including patient interaction platforms, telemedicine solutions, and electronic health records.</p>
                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
               
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Digital Commerce</p>
                            <p className="card-text">Improve the patient experience by using digital commerce solutions for healthcare to pay bills, schedule appointments, and access medical records online via portals and mobile apps.</p>
                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={CRM} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Business Automation</p>
                            <p className="card-text">Routine tasks that can be automated to save expenses and increase overall efficiency in healthcare firms include patient scheduling, appointment reminders, and invoicing procedures.</p>
                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>

            <Slider className="d-none d-lg-block" {...settings3}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Health} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Enterprise Solutions</p>
                            <p className="card-text">Enterprise-level solutions that improve operational effectiveness and streamline data administration include Customer Relationship administration (CRM) systems, Electronic Health Records (EHRs), and other software tools.</p>
                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Legal} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Artificial Intelligence</p>
                            <p className="card-text">AI improves diagnosis, predicts patient outcomes for individualized treatment, and automates tasks, so empowering the healthcare industry. NLP, chatbots, and predictive analytics are a few AI technologies that healthcare organizations might investigate.</p>
                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Education} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">BI and Analytics</p>
                            <p className="card-text">By using business intelligence and analytics technologies to evaluate patient data and find insightful information through dashboards and data visualization, healthcare organizations may improve decision-making.</p>
                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={Finance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Technology Integration</p>
                            <p className="card-text">To increase overall efficiency and expedite procedures, this strategy integrates a variety of technology, including patient interaction platforms, telemedicine solutions, and electronic health records.</p>
                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
               
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Digital Commerce</p>
                            <p className="card-text">Improve the patient experience by using digital commerce solutions for healthcare to pay bills, schedule appointments, and access medical records online via portals and mobile apps.</p>
                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={CRM} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Business Automation</p>
                            <p className="card-text">Routine tasks that can be automated to save expenses and increase overall efficiency in healthcare firms include patient scheduling, appointment reminders, and invoicing procedures.</p>
                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>

            <Slider className="d-md-none" {...settings2}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Health} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Enterprise Solutions</p>
                            <p className="card-text">Enterprise-level solutions that improve operational effectiveness and streamline data administration include Customer Relationship administration (CRM) systems, Electronic Health Records (EHRs), and other software tools.</p>
                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Legal} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Artificial Intelligence</p>
                            <p className="card-text">AI improves diagnosis, predicts patient outcomes for individualized treatment, and automates tasks, so empowering the healthcare industry. NLP, chatbots, and predictive analytics are a few AI technologies that healthcare organizations might investigate.</p>
                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Education} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">BI and Analytics</p>
                            <p className="card-text">By using business intelligence and analytics technologies to evaluate patient data and find insightful information through dashboards and data visualization, healthcare organizations may improve decision-making.</p>
                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={Finance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Technology Integration</p>
                            <p className="card-text">To increase overall efficiency and expedite procedures, this strategy integrates a variety of technology, including patient interaction platforms, telemedicine solutions, and electronic health records.</p>
                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
               
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Digital Commerce</p>
                            <p className="card-text">Improve the patient experience by using digital commerce solutions for healthcare to pay bills, schedule appointments, and access medical records online via portals and mobile apps.</p>
                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={CRM} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <p className="card-title">Business Automation</p>
                            <p className="card-text">Routine tasks that can be automated to save expenses and increase overall efficiency in healthcare firms include patient scheduling, appointment reminders, and invoicing procedures.</p>
                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>
            </div>
            </div>
        </div>
        <div className="container">
        <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="  p-lg-4  mt-4 ">
              <div className="row">
                <p className="heading text-center"  style={{color: "rgb(244, 153, 11)"}}>KEY BENEFITS</p>
                <h1 className="mt-0 text-center" style={{}}>All crucial elements for learning online at any time<br/> and from any place</h1>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                        <img src={Improved_Efficiency} width="60px"/>
                        <p className="mt-3" ><strong>Enhanced Effectiveness</strong></p>
                        <p >Software increases productivity, frees up employees, saves time and effort, and automates manual activities.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start d-flex justify-content-center">
                    <div>
                        <img src={Enhanced_Customer} width="60px"/>
                        <p className="mt-3" ><strong>Enhanced Precision</strong></p>
                        <p >By removing manual procedures and lowering human error, digital solutions increase accuracy.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start d-flex justify-content-center">
                    <div>
                        <img src={Teamwork} width="60px"/>
                        <p className="mt-3" ><strong>Improved Cooperation</strong></p>
                        <p >Software makes it possible for people to collaborate and communicate in real time, improving healthcare care coordination.</p>
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start d-flex justify-content-center">
                    <div>
                        <img src={Cost_Savings} width="60px"/>
                        <p className="mt-3" ><strong>Improved Results for Patients</strong></p>
                        <p >For better patient outcomes, digital platforms enhance decision-making, involvement, and treatment quality.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start d-flex justify-content-center">
                    <div>
                        <img src={Flexibility} width="60px"/>
                        <p className="mt-3" ><strong>Enhanced financial savings</strong></p>
                        <p >Software solutions can save human work, increase efficiency, and reduce waste to save money.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start d-flex justify-content-center">
                    <div>
                        <img src={Decision_Making} width="60px"/>
                        <p className="mt-3" ><strong>Enhanced Security of Data</strong></p>
                        <p >Digital systems improve data security by lowering the possibility of breaches and guaranteeing the privacy of patient information.</p>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
        
      <div className="container">
          <div className="row mb-5">
              <div className="col-12 text-center">
                  <div className="heading py-2" style={{color:"rgb(244, 153, 11)"}}>WHY CHOOSE US?</div>
                  <h1 className="mb-5" style={{}}>Let Dahooks take care of your IT needs for healthcare.</h1>
              </div>
              <div className="col-md-4 col-12 mt-3 mt-md-0">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Security} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Expertise</strong></div>
                      <p>Tech professionals who understand rules and specifications and provide customized solutions for all of your requirements.</p>
                  </div>
              </div>
              
              <div className=" col-md-4 col-12 mt-3 mt-md-0">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Quality} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Quality</strong></div>
                      <p>A track record of meeting deadlines, producing high-quality work, and offering clients the finest options.</p>
                  </div>
              </div>
              <div className="col-md-4 col-12 mt-3 mt-md-0">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Security} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Security</strong></div>
                      <p className="pb-4 mb-2">We put a high priority on data privacy and safeguard customer data with robust security procedures.</p>
                  </div>
              </div>
              <div className=" col-md-4 col-12 mt-3">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Communication} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Communication</strong></div>
                      <p>Communicative responsiveness and close collaboration with clients to comprehend their needs.</p>
                  </div>
              </div>
              <div className="col-md-4 col-12 mt-3">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Flex} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Flexibility</strong></div>
                      <p>We make adjustments to our solutions in response to our clients' evolving needs.</p>
                  </div>
              </div>
              
              <div className="col-md-4 col-12 mt-3">
                  <div className="p-4 cost-box">
                      <div className="mb-3"><img src={Cost} width={"50px"}/></div>
                      <div className="cost-heading mb-3"><strong>Cost-effectiveness</strong></div>
                      <p className="pb-4 mb-2">Economical alternatives that don't sacrifice knowledge or quality.</p>
                  </div>
              </div>
          </div>
      </div>
    </div>
    </>
  );
}
