import React,{useEffect} from "react";
import "../digital-commerce/digital-commerce.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Digital_Commerce_Img from "../../assets/Digital Commerce.jpg";
import Commercial_Experties from "../../assets/Commercial Experties.jpg";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM_Education from "../../assets/CRM_Education.png";
import CRM_Finance from "../../assets/CRM_Finance.png";
import Dot_Net from '../../assets/Dot_Net.png'
import CRM_Health from '../../assets/CRM_Health.png'
import CRM_Publication from '../../assets/CRM_INV.png'
import CRM_ETL from '../../assets/CRM_ETL.png'
import CRM_Finance1 from '../../assets/CRM_Finance1.png'
import CRM_Inventory from '../../assets/CRM_Inventory.png'
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
 
  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: '18px', cursor: 'pointer' }}
      >
        <strong>{isOpen ? '- ' : '+ '}</strong>
        {' '}
        <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default function Digital_Commerce() {
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
 
  return (
    <>
    <Helmet>
      <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Digital Commerce Services | Digital Commerce Solutions - Dahooks" /><meta property="og:description" content="Dahooks offer digital commerce Services that can help you to transform business, and you can lead with confidence. Digital commerce enhance user experience to purchase and sale of goods and services over the internet." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Digital Commerce Services | Digital Commerce Solutions - Dahooks" /><meta name="twitter:description" content="Dahooks offer digital commerce Services that can help you to transform business, and you can lead with confidence. Digital commerce enhance user experience to purchase and sale of goods and services over the internet." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
      <title>Digital Commerce Services | Digital Commerce Solutions - Dahooks</title>
      <link rel="canonical" href="https://dahooks.com/Digital-Commerce" />
    </Helmet>
    <div className="container pt-5">
      <div className="row ">
        <div className="col-lg-12 mt-5 py-4">
          <div className="contact-banner-heading p-lg-4  mt-5">
           <h1 style={{color:"#f4990b"}}>Transformational Solutions for Digital Commerce</h1>
            <div
              className="headings"
              style={{
                fontSize: "28px",
                textAlign: "center",
                lineHeight: "70px",
              }}
            >
             Digital commerce can transform business, and you can lead with confidence.
            </div>
           <p>
           The internet and smartphones have increased digital commerce, allowing companies to provide individualized buying experiences. Retailers improve consumer satisfaction by offering recommendations, easy payment options, and quick delivery, while AI and chatbots enhance sales and customer service.
              </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <img src={Digital_Commerce_Img} width="100%" />
        </div>
      </div>
    </div>
    
      <div className="background py-5">
        <div className="container">
       
          <div className="row">
            <div className="col-lg-6 col-12">
            <div>
                        <img className="kl_img" src={Commercial_Experties} alt="" width={'100%'} />
                    </div>
            </div>
            <div className="col-lg-6 col-12 my-auto">
              <div className="service-content">
                <p
                  className="headings"
                  style={{ textAlign: "left", color: "#f4990b" }}
                >
                 KEY EXPERTISE
                </p>
               <h1>
                  Our Key Digital<br/> Commerce Expertise
                  </h1>
                
                <AccordionItem
                  title="Mobile Solutions"
                  content="Given the rise in the use of mobile devices for online shopping, it is imperative to have a mobile strategy that fulfils your client's needs. Dahooks can assist you with developing mobile apps that offer a smooth user experience for your clients and give you access to real-time data for decision-making."
                  index={1}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />


                <AccordionItem
                  title="CRM Sytems"
                  content="Given the rise in the use of mobile devices for online shopping, it is imperative to have a mobile strategy that fulfils your client's needs. Dahooks can assist you with developing mobile apps that offer a smooth user experience for your clients and give you access to real-time data for decision-making."
                  index={2}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Platform Development & Customization"
                  content="At Dahooks, our specialty is developing e-commerce platforms that are specifically tailored to your unique business needs. Together, you and our skilled development team will create a safe, scalable platform that meets your specific requirements and offers your clients an easy-to-use interface."
                  index={3}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                {/* Add more AccordionItem components as needed */}
                <AccordionItem
                  title="Logistic Systems"
                  content="An essential component of managing an e-commerce company is logistics management. With a range of solutions including inventory management, shipping and delivery management, and real-time order and shipment tracking, Dahooks can help you optimize your logistics operations."
                  index={4}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Inventory Management"
                  content="An effective inventory management system is necessary to guarantee a seamless e-commerce business. You can automate reordering, track inventory across various locations, and receive real-time stock level updates with the assistance of Dahooks' inventory management tools."
                  index={5}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Payment Gateways"
                  content="Creating a smooth payment process for your clients is essential to increasing sales and fostering trust. Dahooks can assist you in putting in place safe payment gateways and multi-currency, multi-payment processing solutions."
                  index={6}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Technology Integration"
                  content="Running a smooth and effective business requires integrating your e-commerce platform with other business systems, such as marketing automation, POS, and ERP. Dahooks can assist you in integrating several technologies to guarantee efficient data transfer and process automation."
                  index={7}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Marketing Automation"
                  content="Driving traffic and sales to your e-commerce firm requires effective marketing. With Dahooks' marketing automation systems, you can monitor client behaviour, develop customized campaigns, and calculate return on investment."
                  index={8}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Customer Engagement"
                  content="Establishing a loyal customer base requires interaction with customers. Chatbots, customer feedback systems, and loyalty programs are just a few individualized customer engagement solutions that Dahooks can assist you with implementing."
                  index={9}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Analytics and BI"
                  content="It would be best to have business intelligence and data analytics to maximize your e-commerce business and make well-informed decisions. You can use analytics and business intelligence (BI) solutions from Dahooks to get real-time insights into sales, inventory control, and customer behaviour."
                  index={10}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12"  >
                  <div className="tagline">
                    <p style={{color:"#f4990b"}}>OUR SERVICES</p>
                    <h1> <strong>How can businesses benefit from digital commerce?</strong></h1>
                  </div>
                </div>
                <div className='col-lg-6 col-12'>
                <div className="service-content" >
                <p><strong>Web Design</strong></p>
                <p>We provide bespoke online solutions that are scalable and reliable for enterprises, like content management systems and e-commerce platforms.</p>

                <p><strong>Development of Mobile Apps</strong></p>
                <p>To meet the needs of the expanding mobile user base, we develop captivating mobile apps for the Android and iOS platforms that offer great user experiences.</p>

                <p><strong>API Middleware Development</strong></p>
                <p>Our area of expertise is developing scalable and secure middleware API solutions that enable seamless connection between various systems and applications.</p>

                <p><strong>Enhancement of Performance</strong></p>
                <p>To improve user experience, we optimize server response time, load time, page speed, caching, and website/app performance.</p>

                
                <p><strong>Improvement</strong></p>
                <p>We provide trustworthy update services to help companies stay ahead of the curve by following the most recent technological developments.</p>

                <p><strong>Redesigning</strong></p>
                <p>Our re-engineering services assist companies in moving from antiquated systems to Contemporary and effective methods for enhanced performance.</p>

                <p><strong>UX Guidance</strong></p>
                <p>We provide UX consultancy to improve user interface, boost engagement, and boost sales through behaviour analysis and creating solutions that satisfy organizational objectives.</p>

               
                       
                    </div>
                </div>
            </div>
        </div>

        <div className="background py-5">
          <div className="container-fluid">
            <div className="row">
              <Slider className="d-none d-lg-block" {...settings1}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={Health} className="card-img-top card-image " alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Healthcare</div>
                              <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                              <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Legal} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Legal</div>
                              <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                              <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Education} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Education</div>
                              <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                              <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Finance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Finance</div>
                              <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                              <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Insurance</div>
                              <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                              <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>

                  {/* <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={CRM} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Wellness</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Mobile_Application} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Banking</div>
                              <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Custom_Software_Solution} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Hospitality</div>
                              <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
              
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={ETL} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">E-Commerce</div>
                              <div className="card-text">Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Salesforce} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Logistics</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div> */}
            </Slider>

            <Slider className="d-none d-md-block d-lg-none" {...settings}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={Health} className="card-img-top card-image " alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Healthcare</div>
                              <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                              <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Legal} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Legal</div>
                              <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                              <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Education} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Education</div>
                              <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                              <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Finance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Finance</div>
                              <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                              <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Insurance</div>
                              <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                              <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>

                  {/* <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={CRM} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Wellness</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Mobile_Application} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Banking</div>
                              <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Custom_Software_Solution} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Hospitality</div>
                              <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
              
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={ETL} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">E-Commerce</div>
                              <div className="card-text">Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Salesforce} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Logistics</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div> */}
            </Slider>

            <Slider className="d-md-none" {...settings2}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={Health} className="card-img-top card-image " alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Healthcare</div>
                              <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                              <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Legal} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Legal</div>
                              <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                              <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Education} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Education</div>
                              <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                              <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Finance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Finance</div>
                              <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                              <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Insurance</div>
                              <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                              <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>

                  {/* <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={CRM} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Wellness</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Mobile_Application} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Banking</div>
                              <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Custom_Software_Solution} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Hospitality</div>
                              <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
              
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={ETL} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">E-Commerce</div>
                              <div className="card-text">Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Salesforce} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Logistics</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div> */}
            </Slider>
            </div>
          </div>
        
        </div>
        <div className="container">
          <div className="row">
            <div className="contact-banner-heading p-lg-4  mt-4">
              {" "}
              <p style={{color:"#f4990b"}}>TYPES OF DIGITAL COMMERCE</p>
              <h1>Digital commerce is the purchase and sale of goods and services over the internet.</h1>
             
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
              <div
                className=" bg-white custom-card p-3"
                
              >
                <h3>B2B</h3>
                <div className="service-points">
                  <ul className="two-col px-3">
                    <div className="row">
                      <div className="col-12">
                        <li>B2B platform development.</li>
                        <li>Integration of ERP and CRM systems.</li>
                        <li>Safe and scalable architectural layout.</li>
                        <li>Advanced analytical and reporting capabilities.</li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mt-3 mt-md-0 d-flex justify-content-center">
              <div
                className=" bg-white custom-card p-3"
                
              >
                <h3>B2C</h3>
                <div className="service-points">
                  <ul className="two-col px-3">
                    <div className="row">
                      <div className="col-12">
                        <li>Mobile-first approach</li>
                        <li>Integration of payment gateway</li>
                        <li>Integration of logistics and delivery</li>
                        <li>Conversion Guidance from UX</li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mt-3 mt-lg-0 d-flex justify-content-center">
              <div
                className=" bg-white custom-card p-3"
                
              >
                <h3>Marketplaces</h3>
                <div className="service-points">
                  <ul className="two-col px-3">
                    <div className="row">
                      <div className="col-12">
                        <li>Onboarding and management of sellers</li>
                        <li>Support for many vendors</li>
                        <li>Capabilities for managing and searching product catalogs</li>
                        <li>Buyers and sellers can review and rate the system.</li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mt-3  d-flex justify-content-center">
              <div
                className=" bg-white custom-card p-3"
                
              >
                <h3>Services</h3>
                <div className="service-points">
                  <ul className="two-col px-3">
                    <div className="row">
                      <div className="col-12">
                        <li>Custom service booking platform development.</li>
                        <li>Real-time booking and scheduling options.</li>
                        <li>Secure payment gateway integration.</li>
                        <li>Logistics and delivery Integration</li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mt-3  d-flex justify-content-center">
              <div
                className=" bg-white custom-card p-3"
                
              >
                <h3>On-demand Solutions</h3>
                <div className="service-points">
                  <ul className="two-col px-3">
                    <div className="row">
                      <div className="col-12">
                        <li>Customized on-demand platform development.</li>
                        <li>Real-time order tracking and administration.</li>
                        <li>integration of a secure payment gateway.</li>
                        <li>Integration of delivery and logistics.</li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 mt-3  d-flex justify-content-center">
              <div
                className=" bg-white custom-card p-3"
                
              >
                <h3>Virtual Products</h3>
                <div className="service-points">
                  <ul className="two-col px-3">
                    <div className="row">
                      <div className="col-12">
                        <li>inventory control for digital commodities.</li>
                        <li>enhanced reporting and analytics features.</li>
                        <li>Client Relationship.</li>
                        <li>Integration of payment gateways for transactions.</li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background py-5">
          <div className="container-fluid">
        <div className="row ">
        <div className="col-lg-12 my-auto py-4">
          <div className="contact-banner-heading p-lg-4  mt-4">
          
            <p
              className="headings"
              style={{ textAlign: "center",color:"#f4990b"}}
            >
             CASE STUDIES
            </p>

            <h1>
              Explore our projects for enterprise application services.
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
      <Slider className="d-none d-lg-block" {...settings1}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={Dot_Net} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                          <div className='d-flex'>
                              
                              <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                              <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                              {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                              <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Health} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Education} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_ETL} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn '>CRM</div>
                              <div className='py-1 px-2 disble-btn '>ETL</div>
                              <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                              <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CRM_Publication} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                        {/* <h5 className="card-title">HomzMart</h5> */}
                        <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>CRM</div>
                            <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                            <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                            {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">GoldMine to SuiteCRM Migration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Inventory Management Using SuiteCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
            </Slider>

            <Slider className="d-none d-md-block d-lg-none" {...settings}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={Dot_Net} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                          <div className='d-flex'>
                              
                              <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                              <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                              {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                              <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Health} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Education} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_ETL} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn '>CRM</div>
                              <div className='py-1 px-2 disble-btn '>ETL</div>
                              <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                              <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CRM_Publication} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                        {/* <h5 className="card-title">HomzMart</h5> */}
                        <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>CRM</div>
                            <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                            <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                            {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">GoldMine to SuiteCRM Migration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Inventory Management Using SuiteCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
            </Slider>

            <Slider className="d-md-none" {...settings2}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={Dot_Net} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                          <div className='d-flex'>
                              
                              <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                              <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                              {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                              <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Health} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Education} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_ETL} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn '>CRM</div>
                              <div className='py-1 px-2 disble-btn '>ETL</div>
                              <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                              <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CRM_Publication} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                        {/* <h5 className="card-title">HomzMart</h5> */}
                        <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>CRM</div>
                            <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                            <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                            {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">GoldMine to SuiteCRM Migration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Inventory Management Using SuiteCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
            </Slider>
            

      </div>
      </div>
        </div>

        
    </>
  );
}
