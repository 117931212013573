import React,{useEffect} from 'react'
import Award1 from '../../assets/clutch-1.png'
import Award2 from '../../assets/clutch-2.png'
import Award3 from '../../assets/iso.png'
import Award4 from '../../assets/iso1.png'
import Award5 from '../../assets/gdpr.png'
import Award6 from '../../assets/dmca.png'
import Award7 from '../../assets/glassdoor.png'
import Award8 from '../../assets/nasscom.png'
import Award9 from '../../assets/cmmi.png'
import Award0 from '../../assets/Award0.png'

export default function Award() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
  return (
    <>
        <div className='container'>
            <div className='heading-text text-center pb-3'>Tapestry Of Success</div>
            <div className='row text-center mb-5'>
                <div className='col-lg-1 col-2 py-2 offset-lg-1 my-auto'>
                    <div><img width={"100%"} src={Award0}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4'>
                    <div><img width={"100%"} src={Award1}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4'>
                    <div><img width={"100%"} src={Award2}/></div>
                </div>
                
                <div className='col-lg-1 col-2 py-4'>
                    <div><img width={"100%"} src={Award3}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4'>
                    <div><img width={"100%"} src={Award4}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4'>
                    <div><img width={"100%"} src={Award5}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4 my-auto'>
                    <div><img width={"100%"} src={Award6}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4 my-auto'>
                    <div><img width={"100%"} src={Award8}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4 my-auto'>
                    <div><img width={"100%"} src={Award7}/></div>
                </div>
                <div className='col-lg-1 col-2 py-4 my-auto'>
                    <div><img width={"100%"} src={Award9}/></div>
                </div>
            </div>
        </div>
    </>
  )
}
