import React from 'react'
import Video from '../../assets/banner-video.mp4'
import '../hero/hero-sec.css'
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <>
      {/* <video playsinline="playsinline" muted="false"  preload="yes" autoplay="autoplay" loop="loop" id="vjs_video_739_html5_api" className="video-js" data-setup='{"autoplay":"any"}'>
        <source src={Video} type="video/mp4"/>
      </video> */}
      {/* <div className="video-wrapper">
        <video autoPlay muted loop playsinline preload="metadata">
          <source src={Video} type="video/mp4"/>
        </video>
      </div>
      <div className="overlayText">
        <div id="topText">
          Developing cutting-edge<br/>
          digital experiences that<br/>
          bring visionary concepts to fruition.
        </div>
        <div className='banner-text mt-2 mt-md-4'>
          Dahooks is a team of strategists, designers, and engineers<br/> 
          who create digital solutions using human-centric ideas for startups,<br/> 
          small and mid-sized businesses, and enterprise businesses.<br/> 
          We help with discovery, design, and development that drive<br/> 
          measurable business results.
        </div>
        <div className='read-more mt-2 mt-md-4'>Read More --</div>
      </div> */}

      <section className="outter option1">
        <section className="video-container">
          <video className='main-video' autoPlay muted loop playsinline preload="metadata">
            <source src={Video} type="video/mp4"/>
          </video>
          <div className="callout">
            <div className='video-heading'>
              Developing cutting-edge<br/>
              digital experiences that<br/>
              bring visionary concepts to fruition.
            </div>
            <div className="desc mt-md-3 mt-2">
              Dahooks is a team of strategists, designers, and engineers<br className='d-none d-lg-block'/> 
              who create digital solutions using human-centric ideas for startups,<br className='d-none d-lg-block'/> 
              small and mid-sized businesses, and enterprise businesses.<br className='d-none d-lg-block'/> 
              We help with discovery, design, and development that drive<br className='d-none d-lg-block'/> 
              measurable business results.
            </div>
            <Link to='/Services' className='read-more'>Read More :-</Link>
          </div>
        </section>
      </section>
    </>
  )
}
