import React, { useState,useEffect }  from 'react'
import './ui.css'

import WireFrames from "../../assets/wireframes.svg";
import tata from '../../assets/tata-logo.png'
import SBM from '../../assets/SBM_logo.png'
import Benefits from "../../assets/ui_benefits.svg";
import Speed from "../../assets/ui_speed.svg";
import Risk from "../../assets/ui_risk.svg";
import Cost from "../../assets/ui_cost.svg";
import Define from "../../assets/ui_define.jpeg";
import Arrow1 from "../../assets/ui_arrow1.svg";
import Arrow2 from "../../assets/ui_arrow2.svg";
import Ui_ux from "../../assets/ui_ux.jpeg";
import Design from "../../assets/ui_design.svg";
import Handover from "../../assets/ui_handover.jpeg";
import Deals from "../../assets/ui_deals.png";
import Gofer from "../../assets/ui_gofer.jpg";
import Recipe from "../../assets/ui_recipe.jpg";
import Sntf from "../../assets/ui_sntf.jpg";
import Sparker from "../../assets/ui_sparker.png";
import Packagedelivery from "../../assets/ui_packgedelivery.jpg";
import { useLocation } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Discover from "../../assets/ui_discover.jpeg";

import Process from "../../assets/ui_process.png";

import Edumcomp from '../../assets/educomp.png'
import Yennes from '../../assets/yennes.png'
import FNB from '../../assets/FNB.png'
import Ookla from '../../assets/ookla.png'
import Packsys from '../../assets/packsys.png'
import Safe from '../../assets/safe.png'
import Sani from '../../assets/sani.png'
import {Helmet} from "react-helmet";

const ContactUs = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onSumbit=()=>{
    if(name=='' && email=="" && message==""){
      setErrr("All field are required!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    const emailReg=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!email.match(emailReg)){
          setErrr("Please enter valid email!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
        return false
        }

        if(message?.length>500){
          setErrr("Messahe should be contain 500 charectors !!");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return false
        }
     
        axios.post("https://smtp.taction.in/api/api/v2/send-mail",{
          name:name,
          email:email,
          message:message
        }).then(res=>{
          if(res && res?.status==200){
            toast.success('Your query is Submitted ', {
              autoClose: 2000,
            });
            setTimeout(() => {
              setOpen(false);
              setEmail("");
              setName("");
              setMessage("")
            }, 2500);
          }
         
        }).catch((err)=>{
          console.log(err)
        })
        console.log("Contact")

    
    
  }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
    return (
        <>
        <Helmet>
            <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="UI/UX Design Services | UI/UX Development Company in India & USA" /><meta property="og:description" content="Dahooks is the leading UI/UX design Services provider company in India & USA. We offer best UI/UX design services for custom software, Web and Mobile Apps. Hire UI/UX designers today." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="UI/UX Design Services | UI/UX Development Company in India & USA" /><meta name="twitter:description" content="Dahooks is the leading UI/UX design Services provider company in India & USA. We offer best UI/UX design services for custom software, Web and Mobile Apps. Hire UI/UX designers today." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
            <title>UI/UX Design Services | UI/UX Development Company in India & USA</title>
            <link rel="canonical" href="https://dahooks.com/ui-ux" />
        </Helmet>
        <ToastContainer style={{zIndex:900}} />
            <div className="container">
                <div className="row pt-5">
                    <div className="col-lg-12 mt-5 py-4">
                        <div className="contact-banner-heading p-lg-4  mt-5">
                            {" "}
                            <h1>
                                Services for Creating Beautiful Websites for All Tastes
                            </h1>
                            <p>
                            Designing an intuitive and powerful user interface requires careful attention to many small elements. Our organization has created a streamlined method to make this intricate procedure simpler. Finding the difficulties in our client's design requirements is the first thing we do. This entails being aware of their target market, objectives, and any roadblocks. Through comprehension of these obstacles, we may devise strategies that address each one separately, resulting in a seamless and effective design process. With a well-defined plan in place, we move forward with developing useful user interfaces that satisfy the audience's requirements and tastes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" background">
                <div className="container">
                    <div className="row">
                        <div className="contact-banner-heading p-lg-4">
                            {" "}
                            <p>
                                The Impact We Create
                            </p>
                            <h1 className='ui-heading'>
                                We state that the crux of inventiveness lies in the discovery. Our "Discovery Workshop" teaches how to combine extreme discipline with a dash of insanity to create masterpieces.
                            </h1>

                        </div>
                    </div>
                    <div className="row">
                        <img className="kl_img" src={WireFrames} alt="" width="550" />
                    </div>
                </div>
            </div>

            <section className="workshop-service-section" id="discovery-workshop">
                <div className="container">
                    <div className="service-title-box">
                        <h2><b>Why is a "Workshop" Necessary for Your Idea?</b></h2>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="service-box">
                                <h3>Consider</h3>
                                <p>Not every requirement is ready for development.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="service-box">
                                <h3>Arrange</h3>
                                <p>Thoughts develop and must be contained inside a bound</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="service-box">
                                <h3>Set priorities.</h3>
                                <p>Arrange your ideas to make the most impact.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="service-box">
                                <h3>Objectives</h3>
                                <p>A high-quality product needs goals that are well-defined.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="service-box">
                                <h3>Calculate</h3>
                                <p>Defining scope in order to provide a precise budget and schedule</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="service-box">
                                <h3>Schedule</h3>
                                <p>Selecting the best technological development strategy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="discovery-benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 my-auto py-4">
                            <div className="contact-banner-heading p-lg-4  mt-4">
                                {" "}
                                <h1>
                                    Web Design Services to Suit Every Taste Palette
                                </h1>
                                <p>
                                    Designing an intuitive and powerful user interface requires careful attention to many small elements. Our organization has created a streamlined method to make this intricate procedure simpler. Finding the difficulties in our client's design requirements is the first thing we do. This entails being aware of their target market, objectives, and any roadblocks. Through comprehension of these obstacles, we may devise strategies that address each one separately, resulting in a seamless and effective design process. With a well-defined plan in place, we move forward with developing useful user interfaces that satisfy the audience's requirements and tastes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="benefits-services">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="benefits-box text-center text-md-start mt-3">
                                    <img src={Benefits} alt="" width="90px" />
                                    <h3>Clear Strategy</h3>
                                    <p>Together, we eliminate unnecessary elements and maintain your attention while defining essential system features so that your product is ready for launch.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="benefits-box text-center text-md-start mt-3">
                                    <img src={Risk} alt="" width="90px" />
                                    <h3>Speed</h3>
                                    <p>Maximizing our involvement and adopting an agile attitude in contrast to the conventional approach to yield observable results.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="benefits-box text-center text-md-start mt-3">
                                    <img src={Speed} alt="" width="90px" />
                                    <h3>Lower Risk</h3>
                                    <p>Decide on a sustainable and attainable goal before throwing a big chunk of money into the project at first and going all in.</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="benefits-box text-center text-md-start mt-3">
                                    <img src={Cost} alt="" width="90px" />
                                    <h3>Cost Effective</h3>
                                    <p>We collaborate with you to determine the minimum viable product, freeing you up to concentrate on growth while prioritizing investments for a higher return.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <div className='container'>
                <div className="row">
                    {/* <div className="discovery-benefits"> */}
                    <div className="col-lg-12 my-auto py-4">
                        <div className="contact-banner-heading p-lg-4  mt-4">
                            {" "}
                            <p>The Process</p>
                            <h1>

                                Our Web Design Service's Procedure

                            </h1>
                            <p>
                            We allocate a UI/UX designer, a technical team, and a dedicated analyst to each workshop so that together you can fully realize your ideas and provide the most cutting-edge, technically sound solutions.


                            </p>
                            <img src={Process} alt="" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
            
            {/* </div> */}


            <div className='container'>
                <div className='row'>
                    <div className="col-lg-6 col-12">
                        <div>
                            <img className="kl_img" src={Discover} alt="" width={'100%'} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 my-auto'>
                        <div className="service-content">
                            <h4 className='mt-3 mt-lg-0'>Market, Problem, and Target Audience</h4>
                            <h1>Find & Create</h1>

                            <span className="separator-line"></span>

                            <h2>Examination of the Market</h2>
                            <p>Examine previous work on comparable solutions in the market and improve the experience.</p>

                            <h2>Conditions</h2>
                            <p>Use in-depth brainstorming sessions to identify the main needs and the solution's vision.</p>

                            <h2>Ideation</h2>
                            <p>Determine the main business issue that has to be resolved with the end user in mind.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-6 offset-3">
                <div>
                    <img className="kl_img" src={Arrow1} alt="" width={'100%'} />
                </div>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className="col-lg-6 col-12 my-auto order-2 order-lg-1">
                        <div className="service-content">
                            <h4 className='mt-3 mt-lg-0'>The Core's blueprint</h4>
                            <h1>Identify and Clarify</h1>

                            <span className="separator-line"></span>

                            <h2>Mindmap</h2>
                            <p>Creating a logical hierarchy of essential modules and activities for information architecture design</p>

                            <h2>User Paths</h2>
                            <p>Heuristic study of user behavior by starting with design components that users are likely to expect</p>

                            <h2>Diagram</h2>
                            <p>Conceptual block-based model to reveal important information pieces' visual representation</p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className="service-content">
                            <img className="kl_img" src={Define} alt="" width={'100%'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-6 offset-3">
                <div>
                    <img className="kl_img1" src={Arrow2} alt="" width={'100%'} />
                </div>
            </div>


            <div className='container'>
                <div className='row'>
                    <div className="col-lg-6 col-12">
                        <div>
                            <img className="kl_img" src={Ui_ux} alt="" width={'100%'} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 my-auto'>
                        <div className="service-content">
                            <h4 className='mt-3 mt-lg-0'>Interaction, Feeling, and Images</h4>
                            <h1>Design of User Interface/UX</h1>

                            <span className="separator-line"></span>

                            <h2>Interface</h2>
                            <p>Being a top website design firm, we carefully build gorgeous pixels to produce an aesthetically pleasing interface of pertinent panels.</p>

                            <h2>Digital User Interface</h2>
                            <p>Using our mobile app design services, we skillfully integrate content, interaction, and interface to produce a functional design.</p>

                            <h2>Aim for Design</h2>
                            <p>With our mobile app design services, we produce solutions that adhere to resolving the issue at its heart while still looking amazing.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className='container mt-4'>
                <div className='row mt-5 pt-5'>
                    <div className="col-lg-6 col-12 my-auto order-2 order-lg-1">
                        <div className="service-content">

                            <h1>Design is not only appearance.</h1>
                            <h1 className="red-color">Nevertheless, how it functions.</h1>
                            <p>—Steve Jobs</p>


                            <p>The creation of complex systems is simple. Creating practical designs that make people feel good about using a website, piece of software, or application requires a surgical approach.</p>

                            <p>As a web design firm, we develop straightforward and significant interfaces that span multiple screens, necessitating meticulous coordination of all the essential components.</p>

                        </div>
                    </div>
                    <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className="service-content">
                            <img className="kl_img" src={Design} alt="" width={'100%'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-6 offset-3">
                <div>
                    <img className="kl_img" src={Arrow1} alt="" width={'100%'} />
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="col-lg-6 col-12 my-auto order-2 order-lg-1">
                        <div className="service-content">
                            <h4 className='mt-4 mt-lg-0'>From Exploration to Creation</h4>
                            <h1>Transfer of Authority</h1>

                            <span className="separator-line"></span>
                            <h3>Moving on from the phase of discovery to the stage of development</h3>

                            <p>Throughout the handover process, the technical team—regardless of their identity—acts as a catalyst to maintain the same depth and momentum of the designed concept. We guarantee this procedure as a top online and mobile app design business by providing functional flow, design guidelines, and meticulous visual design that are prepared to be delivered in combination with our web and mobile design services.</p>


                        </div>
                    </div>
                    <div className='col-lg-6 col-12 order-1 order-lg-2'>
                        <div className="service-content">
                            <img className="kl_img" src={Handover} alt="" width={'100%'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12 my-auto py-4">
                        <div className="contact-banner-heading p-lg-4  mt-4">
                            {" "}
                            <p><b>Key Insights</b></p>
                            <h1>Well-informed</h1>
                            <div className="row">

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>Every workshop can be tailored to your specific needs and where you are right now.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>Subject Matter Experts lead the ideation and analysis phase at the beginning of the process.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>Analyst requirements provide the basis for wireframes, which are followed.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>Wireframes and user journeys are the foundation for all UI designs.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>There must be a unique user interface flow with comprehensive displays designed for every system stakeholder.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>Following UI designs, an interactive prototype with designs will be showcased.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4 pb-5'>As shown in the Workshop Model, each step comes before the one before it.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>If there is a phase in the workshop that you have already completed or are capable of completing on your own, we will gladly skip it.</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div className="service-box mt-4">
                                        <h3 className='ui-card-text p-4'>We use every contemporary tool available, including Sketch, Invision, and occasionally other programs like Principal, Flinto, and Adobe XD.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <div className="row">
                    <div className="col-lg-12 my-auto py-4 mt-4 mt-md-0">
                        <div className="contact-banner-heading p-lg-4">
                            {" "}
                            <h1>
                                Successful Designs through Discovery Workshop

                            </h1>
                            <p>
                                View the Shiny Bits Of Our Work
                            </p>

                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
                                    <div className="card" >
                                        <img src={Deals} alt="" width="100%" />
                                        <div className="card-body">
                                            <div className="ui-crd-text">ECOMMERCE</div>
                                            <div className="card-title">Daily Deals</div>
                                            <div className='d-flex'>
                                                <div className='py-1 px-2 disble-btn'>Android</div>
                                                <div className='py-1 px-2 disble-btn'>iOS</div>
                                                <div className='py-1 px-2 disble-btn'>NodeJs</div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
                                    <div className="card" >
                                        <img src={Gofer} alt="" width="100%" />
                                        <div className="card-body">
                                            <div className="ui-crd-text">ECOMMERCE</div>
                                            <div className="card-title">Gofer</div>
                                            <div className='d-flex'>
                                                <div className='py-1 px-2 disble-btn'>Android</div>
                                                <div className='py-1 px-2 disble-btn'>iOS</div>
                                                <div className='py-1 px-2 disble-btn'>NodeJs</div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                    <div className="card" >
                                        <img src={Recipe} alt="" width="100%" />
                                        <div className="card-body">
                                            <div className="ui-crd-text">FOOD / RESTAURANT</div>
                                            <div className="card-title">Recipe App</div>
                                            <div className='d-flex'>
                                                <div className='py-1 px-2 disble-btn'>Android</div>
                                                <div className='py-1 px-2 disble-btn'>iOS</div>
                                                <div className='py-1 px-2 disble-btn'>NodeJs</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mt-4">
                                    <div className="card" >
                                        <img src={Sntf} alt="" width="100%" />
                                        <div className="card-body">
                                            <div className="ui-crd-text">ECOMMERCE</div>
                                            <div className="card-title">SNTF</div>
                                            <div className='d-flex'>
                                                <div className='py-1 px-2 disble-btn'>laravel</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-4">
                                    <div className="card" >
                                        <img src={Sparker} alt="" width="100%" />
                                        <div className="card-body">
                                            <div className="ui-crd-text">SOCIAL MEDIA</div>
                                            <div className="card-title">Sparker</div>
                                            <div className='d-flex'>
                                                <div className='py-1 px-2 disble-btn '>Android</div>
                                                <div className='py-1 px-2 disble-btn '>iOS</div>
                                                <div className='py-1 px-2 disble-btn '>PHP</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-4">
                                    <div className="card" >
                                        <img src={Packagedelivery} alt="" width="100%" />
                                        <div className="card-body">
                                            <div className="ui-crd-text">TRANSPORT</div>
                                            <div className="card-title">Package Delivery</div>
                                            <div className='d-flex'>
                                                <div className='py-1 px-2 disble-btn '>Android</div>
                                                <div className='py-1 px-2 disble-btn '>iOS</div>
                                                <div className='py-1 px-2 disble-btn '>NodeJs</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="container">
                <div className="row">
                    <div className="col-lg-12 my-auto py-4">
                        <div className="contact-banner-heading p-lg-4  mt-4">

                            <h1>
                                <b>FAQs</b>
                            </h1>

                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header mb-0" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <b>What is a Discovery Workshop?</b>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>A Discovery Workshop is a collaborative process where we work with you to identify and prioritize critical system features for your website or mobile app. It helps to define a clear strategy, identify the target audience, and minimize risk while optimizing cost and time.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header mb-0" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <b>How do you ensure the quality of your web and mobile app design services?</b>

                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p> We have a dedicated team of analysts, UI/UX designers, and technical experts who work together to create a functional and visually appealing design that meets the needs of your target audience. We also conduct user testing and feedback to ensure the quality and effectiveness of our design solutions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header mb-0" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <b>What is your approach to website design?</b>
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Our approach is focused on challenges and solutions, with the audience at the center of the design process. We prioritize the discovery and organization of ideas, quantification of scope, and choosing the right technology approach to development. We also follow an agile methodology for speed and adaptability to changes.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



            <div className='container-fluid mb-4'>
                <div className='row background-clr'>
                <div className='col-lg-6 col-12' style={{overflow:'hidden'}}>
                    <div className="bd-best mb-4" style={{rotate:'-20deg',marginLeft:'-52px'}}>
                    <div className="slider mt-5">
                        <div className="slide-track">
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="bd-best mb-4" style={{rotate:'-20deg',marginLeft:'-52px'}}>
                    <div className="slider mt-5">
                        <div className="slide-track">
                        
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="bd-best mb-4" style={{rotate:'-20deg',marginLeft:'-52px'}}>
                    <div className="slider mt-5">
                        <div className="slide-track">
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide my-auto">
                            <img
                            src={tata}
                            height={60}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={SBM}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Sani}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Safe}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Packsys}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Ookla}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={FNB}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Yennes}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        <div className="slide">
                            <img
                            src={Edumcomp}
                            height={80}
                            width={200}
                            alt=""
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className='col-lg-6 col-12 my-auto'>
                    <div className='my-auto py-4'>
                        <div className='text-center'>
                            <div className='state'>Schedule a Meeting with Our Experts</div>
                            <div>Share a brief about your project and get a guaranteed response within 24 hours.</div>
                        </div>
                        <div className='mt-4 d-flex justify-content-center'>
                            {/* <button onClick={onOpenModal} className="btn btn-success" type="submit">
                                Contact Us
                            </button> */}
                            <form action="/action_page.php" style={{width:"70%"}}>
                                <label className="label-text" for="fname"><strong>Name</strong> <sup style={{color:'red'}}>*</sup></label>
                                <input type="text" onChange={(e)=>{
                                setName(e.target.value)
                                }} value={name} id="fname" name="firstname" placeholder="Enter Your Name.." style={{borderTop: "none",borderLeft: "none",borderRight: "none",background: "#f5f5f5"}}/>

                                <label className="label-text mt-3" for="lname"><strong>Email</strong><sup style={{color:'red'}}>*</sup></label>
                                <input type="text" onChange={(e)=>{
                                setEmail(e.target.value)
                                }} value={email} id="lname" name="lastname" placeholder="Enter Your Email.."style={{borderTop: "none",borderLeft: "none",borderRight: "none",background: "#f5f5f5"}}/>
                                <label className="label-text mt-3 mb-2" for="subject"><strong>Message</strong><sup style={{color:'red'}}>*</sup></label>
                                <textarea onChange={(e)=>{
                                if(e.target.value?.length>500){
                                    setErrr("Messahe should be contain 500 charectors !!");
                                    setTimeout(() => {
                                    setErrr("");
                                    }, 3000);
                                    return false
                                }
                                else{
                                    setMessage(e.target.value)
                                }
                                }} id="subject" value={message} name="subject" placeholder="Write something.." style={{height:'100px',borderTop: "none",borderLeft: "none",borderRight: "none",background: "#f5f5f5"}}></textarea>

                                {/* <input type="submit" value="Submit"/> */}
                                <div className="text-center mt-3">
                                <button onClick={onSumbit} type="button" className="btn btn-dark" style={{width:'100%'}}>Submit</button>
                                </div> 
                                <div style={{height:20,color:"red",fontSize:12,marginTop:5}}>{errr?errr:""}</div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default ContactUs;