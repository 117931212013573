import React from 'react'
import Banner1 from '../../assets/india-shelter-web.jpg'
import Banner2 from '../../assets/NOD-showcase-web.jpg'
import Banner3 from '../../assets/focus-showcase-web.jpg'
import Banner4 from '../../assets/sujata-showcase-web.jpg'

export default function OurWork() {
  return (
    <>
    <div style={{backgroundColor: "#f7f7f7"}}>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8 col-12 offset-lg-2'>
                    <div className='heading-text text-center py-5'>Our Work</div>
                    {/* <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={Banner3} className="d-block w-100" alt="..."/>
                            </div>
                            <div className="carousel-item">
                                <img src={Banner4} className="d-block w-100" alt="..."/>
                            </div>
                        </div>
                    </div> */}
                    <div id="carouselExampleControls" className="carousel slide pb-5" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner1} className="d-block w-100" alt="..." style={{borderRadius: "20px"}}/>
                            </div>
                            <div className="carousel-item">
                                <img src={Banner2} className="d-block w-100" alt="..." style={{borderRadius: "20px"}}/>
                            </div>
                            {/* <div className="carousel-item">
                                <img src={Banner3} className="d-block w-100" alt="..." style={{borderRadius: "20px"}}/>
                            </div>
                            <div className="carousel-item">
                                <img src={Banner4} className="d-block w-100" alt="..." style={{borderRadius: "20px"}}/>
                            </div> */}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                            
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>  
    </>
  )
}
